import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {saveAs} from "file-saver";
import {CanceledError} from "axios";
import {Button, IButtonProps} from "../../components";
import {ScansService, ToastService} from "../../services";

export interface IExportScanReportPdfButtonProps extends IButtonProps {
}

export const ExportScanReportPdfButton: FC<IExportScanReportPdfButtonProps> = ({
  ...buttonProps
}) => {
  const { t } = useTranslation();

  const onExport = () => {
    ScansService.exportReportAsPdf().then((res) => {
      const fileName = `scan-report-${moment().format('YYYY-MM-DD HH:mm')}.pdf`;
      saveAs(res, fileName);
      ToastService.success('toast.reportPdfExported');
    }).catch((err) => {
      if (!(err instanceof CanceledError)) {
        ToastService.showHttpError(err, 'toast.reportPdfExportingFailed');
      }
    });
  };

  return (
    <Button
      theme="primary"
      {...buttonProps}
      onClick={onExport}
    >
      {t('common.exportAsPdf')}
    </Button>
  );
};
