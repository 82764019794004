import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import classNames from "classnames";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {Button, Logo} from "../../../components";
import {VisibilityIcon, VisibilityOffIcon} from "../../../icons";
import {ForgotPasswordPopup} from "../../../parts";
import {setToken} from "../../../redux/reducers/auth.reducer";
import {AuthService, ToastService} from "../../../services";

export const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showForgotPasswordPopup, setShowForgotPasswordPopup] = useState(false);

  const authPageClass = classNames(
    'flex-center flex-col min-h-screen bg-onboard1 bg-cover bg-center',
    'px-4 py-8 md:px-8 md:py-16 lg:px-12 lg:py-24 xl:px-14 xl:py-39',
  );

  const formSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('validationErrors.invalidEmail'))
      .required(t('validationErrors.requiredField')),
    password: Yup.string().required(t('validationErrors.requiredField')),
  });

  const initialFormValues = {
    email: '',
    password: '',
  };

  const onSubmit = (form) => {
    setLoading(true);
    AuthService.login(form.email, form.password, false)
      .then((res) => {
        dispatch(setToken(res.data.token));
        navigate('/');
      })
      .catch((err) => ToastService.showHttpError(err, 'toast.loginFailed'))
      .finally(() => setLoading(false));
  };

  return (
    <div className={authPageClass}>
      <Logo className="md:mb-15 mx-auto" />

      <div className="max-w-125 md:max-w-480 max-md:w-full md:grid grid-cols-2 gap-4 lg:gap-10 max-lg:px-8 max-lg:py-16">
        <div className="flex flex-col justify-center">
          <h1 className="max-md:hidden text-40p text-center mb-8">{t('common.login')}</h1>
          <Formik
            initialValues={initialFormValues}
            validationSchema={formSchema}
            onSubmit={onSubmit}
          >
            {(formik) => {
              const { errors, touched } = formik;
              return (
                <Form className="formik-form">
                  <div className={classNames(
                    'form-field full-width rounded-sm size-lg',
                    errors.email && touched.email && 'is-invalid',
                  )}>
                    <Field
                      name="email"
                      placeholder={t('common.email')}
                    />
                    <ErrorMessage name="email" component="div" className="invalid-feedback ml-4" />
                  </div>

                  <div className={classNames(
                    'form-field full-width rounded-sm size-lg mt-6',
                    errors.password && touched.password && 'is-invalid',
                  )}>
                    <div className="relative flex items-center">
                      <Field
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        placeholder={t('common.password')}
                      />
                      <div
                        className="absolute right-4 text-black/54 cursor-pointer"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </div>
                    </div>
                    <ErrorMessage name="password" component="div" className="invalid-feedback ml-4" />
                  </div>

                  <div className="text-right mt-2">
                    <span
                      className="text-typo1 hover:text-primary text-sm underline cursor-pointer"
                      onClick={() => setShowForgotPasswordPopup(true)}
                    >
                      {t('auth.forgotPassword')}
                    </span>
                  </div>

                  <Button
                    type="submit"
                    className="!h-14 mt-8"
                    fullWidth
                    loading={loading}
                  >
                    {t('common.login')}
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </div>
        <div className="max-md:hidden flex flex-col justify-center pt-8">
          <img className="max-w-full" src="/assets/images/onboard/onboard1.png" alt="" />
        </div>
      </div>

      {showForgotPasswordPopup && (
        <ForgotPasswordPopup onClose={() => setShowForgotPasswordPopup(false)} />
      )}
    </div>
  );
};
