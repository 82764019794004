import React, {useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Button} from "../../../../components";
import {PatientLayout} from "../../../../layouts";
import {
  PageBackButton,
  ExerciseCard,
  ExerciseChallengeCard,
  TrainingModal,
  ExerciseTutorialCard,
} from "../../../../parts";
import {EXERCISE_TYPE, LOADING_STATUS} from "../../../../resources/enums";
import {useSelector} from "../../../../redux/store";

export const FirstAid = () => {
  const { t } = useTranslation();
  const { type } = useParams<{ type: EXERCISE_TYPE }>();
  const { firstAidKits, firstAidKitsLoadingStatus } = useSelector((root) => root.patient);
  const [showTrainModal, setShowTrainModal] = useState(false);

  const exercise = useMemo(() => {
    const aidKit = firstAidKits.find((item) => item.type === type);
    if (aidKit) {
      return aidKit;
    }
    return firstAidKitsLoadingStatus === LOADING_STATUS.SUCCESS ? null : undefined;
  }, [firstAidKits, firstAidKitsLoadingStatus, type]);

  const sidebar = (
    <>
      <ExerciseChallengeCard />
      <ExerciseTutorialCard className="mt-4" />
    </>
  );

  return (
    <PatientLayout sidebar={sidebar}>
      <PageBackButton />

      <h1 className="mt-8">{t(`patient.firstAids.${type}`)}</h1>

      <p className="text-typo1 mt-6">
        {t('patient.yourPersonalRecommendation')}
      </p>

      {exercise && (
        <>
          <ExerciseCard
            className="max-w-92 mt-6"
            exercise={exercise}
            active
            onClick={() => setShowTrainModal(true)}
          />

          <Button className="mt-6" onClick={() => setShowTrainModal(true)}>
            {t('patient.startTraining')}
          </Button>
        </>
      )}

      {exercise === null && (
        <div className="max-w-92 h-22 card1 flex-center mt-6">
          {t('common.notAvailable')}
        </div>
      )}

      {showTrainModal && (
        <TrainingModal
          exercise={exercise}
          reportWhenFinished={false}
          onClose={() => setShowTrainModal(false)}
        />
      )}
    </PatientLayout>
  );
};
