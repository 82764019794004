export class FileModel {
  id: string;
  src: string;
  thumbnailSrc: string;
  filename: string;
  mimetype: number;
  encoding: string;
  etag: string;
  key: string;
  thumbnail: string;
  createdAt: string;
  updatedAt: string;

  constructor(init?: Partial<FileModel>) {
    const data = {
      id: null,
      ...init,
    };

    this.id = data.id;
    this.src = data.src;
    this.thumbnailSrc = data.thumbnailSrc;
    this.filename = data.filename;
    this.mimetype = data.mimetype;
    this.encoding = data.encoding;
    this.etag = data.etag;
    this.key = data.key;
    this.thumbnail = data.thumbnail;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }
}
