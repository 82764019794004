import React, {FC} from "react";
import classNames from "classnames";
import {Image} from "../../components";
import {GENDER} from "../../resources/enums";

export interface IGenderSelectProps {
  className?: string;
  value?: GENDER;
  onChange(value: GENDER): void;
}

const genderOptions = [
  {
    text: 'common.male',
    value: GENDER.MALE,
    image: '/assets/images/onboard/ic_avatar_male.png',
    activeImage: '/assets/images/onboard/ic_avatar_male_selected.png',
  },
  {
    text: 'common.female',
    value: GENDER.FEMALE,
    image: '/assets/images/onboard/ic_avatar_female.png',
    activeImage: '/assets/images/onboard/ic_avatar_female_selected.png',
  },
];

export const GenderSelect: FC<IGenderSelectProps> = ({
  className,
  value,
  onChange,
}) => {
  return (
    <div className={classNames('flex flex-wrap gap-x-4 gap-y-2', className)}>
      {genderOptions.map((option) => {
        const isActive = option.value === value;
        return (
          <div
            key={option.value}
            className={classNames(
              'relative w-41 h-50 flex-center overflow-hidden cursor-pointer',
              'bg-card1 rounded-10p p-3',
              isActive ? 'border-2 border-stroke2' : 'border border-stroke1',
            )}
            onClick={() => onChange(option.value)}
          >
            <Image
              src={isActive ? option.activeImage : option.image}
            />

            <div className="absolute left-3 bottom-3 w-6 h-6 flex-center border border-primary rounded-full">
              {isActive && (
                <div className="w-4 h-4 bg-primary rounded-full" />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
