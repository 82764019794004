import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

export interface IWebcamRef {
  video: HTMLVideoElement;
  stream: MediaStream;
  mediaSettings: MediaTrackSettings;
}

export interface WebcamProps {
  className?: string;
  constraints?: MediaStreamConstraints;
  onInit?: (ref: IWebcamRef) => void;
  onError?: (error: string) => void;
}

export const Webcam: FC<WebcamProps> = ({ className, onInit, onError }) => {
  const [width, setWidth] = useState<number>();
  const [height, setHeight] = useState<number>();
  const videoRef = useRef<HTMLVideoElement>();
  const streamRef = useRef<MediaStream>();

  useEffect(() => {
    const timer = setTimeout(() => {
      setWidth(videoRef.current.clientWidth);
      setHeight(videoRef.current.clientHeight);
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  });

  const initCamera = useCallback(() => {
    if (!width || !height || !videoRef.current) {
      return;
    }

    navigator.mediaDevices
      .getUserMedia({
        video: {
          width,
          height,
          facingMode: 'environment',
        },
        audio: false,
      })
      .then((stream) => {
        streamRef.current = stream;
        const settings = stream.getVideoTracks()[0].getSettings();
        const video = videoRef.current;
        video.srcObject = stream;
        video.play();

        if (onInit) {
          onInit({
            video: videoRef.current,
            stream,
            mediaSettings: settings,
          });
        }
        if (onError) {
          onError(undefined);
        }
      })
      .catch((err) => {
        console.log('webcam init error', err);
        if (onError) {
          onError(err?.message || 'Initializing webcam failed');
        }
      });
  }, [width, height, onInit, onError]);

  useEffect(() => {
    const closeCurrentStream = () => {
      if (streamRef.current) {
        streamRef.current.getVideoTracks().forEach((track) => {
          streamRef.current.removeTrack(track);
          track.stop();
        });
      }
    };

    initCamera();

    return closeCurrentStream;
  }, [initCamera, onError, onInit]);

  return <video className={className} ref={videoRef} />;
};
