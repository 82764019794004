import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import {Route, Routes, useLocation} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import {SpinnerContainer} from "./components";
import {AuthProvider} from "./providers/AuthProvider";
import {AuthRouting} from "./pages/Auth/routing";
import {PatientRouting} from "./pages/Patient/routing";
import {PublicRouting} from "./pages/Public/routing";
import {DisclaimerPopup} from "./parts";
import {getAccount} from "./redux/selectors";

function App() {
  const location = useLocation();
  const user = useSelector(getAccount);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, [location.pathname]);

  return (
    <div>
      <AuthProvider>
        {!user ? (
          <Routes>
            {PublicRouting}
            <Route path="/*" element={<AuthRouting />} />
          </Routes>
        ) : (
          <>
            <Routes>
              {PublicRouting}
              <Route path="/*" element={<PatientRouting />} />
            </Routes>

            {user.isFirstLogin && (
              <DisclaimerPopup />
            )}
          </>
        )}
      </AuthProvider>

      <SpinnerContainer />
      <ToastContainer />
    </div>
  );
}

export default App;
