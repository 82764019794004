import React, {FC} from "react";
import classNames from "classnames";

export type SpinnerType = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export interface ILoadingSpinnerProps {
  className?: string;
  size?: SpinnerType;
}

export const LoadingSpinner: FC<ILoadingSpinnerProps> = ({
  className,
  size = 'md',
}) => {
  const spinnerClass = classNames(
    'text-primary animate-spin',
    size === 'md' && 'w-12 h-12',
    size === 'sm' && 'w-10 h-10',
    size === 'xs' && 'w-8 h-8',
    className,
  );
  return (
    <svg className={spinnerClass} fill="none" viewBox="0 0 24 24">
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
  );
};
