import type {PayloadAction} from "@reduxjs/toolkit";
import {createSlice} from "@reduxjs/toolkit";
import {LOADING_STATUS} from "../../resources/enums";
import {ChallengeModel, ExerciseModel, IssueModel, PhotoTipModel, ScanModel} from "../../resources/models";
import {ITrainStatus} from "../../resources/interfaces";

export interface IPatientState {
	scan?: ScanModel;
	spineScan?: ScanModel;
	scanLoadingStatus: LOADING_STATUS;
	challenges: ChallengeModel[];
	challenge?: ChallengeModel;
	challengeLoadingStatus: LOADING_STATUS;
	dailyExercises: ExerciseModel[];
	exercisesLoadingStatus: LOADING_STATUS;
	issues: IssueModel[];
	issuesLoadingStatus: LOADING_STATUS;
	photoTips: PhotoTipModel[];
	photoTipsLoadingStatus: LOADING_STATUS;
	firstAidKits: ExerciseModel[];
	firstAidKitsLoadingStatus: LOADING_STATUS;
	trainStatus?: ITrainStatus;
	trainStatusLoadingStatus: LOADING_STATUS;
}

const initialState: IPatientState = {
	scanLoadingStatus: LOADING_STATUS.NONE,
	challenges: [],
	challengeLoadingStatus: LOADING_STATUS.NONE,
	dailyExercises: [],
	exercisesLoadingStatus: LOADING_STATUS.NONE,
	issues: [],
	issuesLoadingStatus: LOADING_STATUS.NONE,
	photoTips: [],
	photoTipsLoadingStatus: LOADING_STATUS.NONE,
	firstAidKits: [],
	firstAidKitsLoadingStatus: LOADING_STATUS.NONE,
	trainStatusLoadingStatus: LOADING_STATUS.NONE,
};

export const PatientSlice = createSlice({
	name: 'Patient',
	initialState,
	reducers: {
		setScan: (state, action: PayloadAction<ScanModel>) => {
			state.scan = action.payload;
		},
		setSpineScan: (state, action: PayloadAction<ScanModel>) => {
			state.spineScan = action.payload;
		},
		setScanLoadingState: (state, action: PayloadAction<LOADING_STATUS>) => {
			state.scanLoadingStatus = action.payload;
		},
		setChallenges: (state, action: PayloadAction<ChallengeModel[]>) => {
			state.challenges = action.payload;
		},
		setChallenge: (state, action: PayloadAction<ChallengeModel>) => {
			state.challenge = action.payload;
		},
		setChallengeLoadingState: (state, action: PayloadAction<LOADING_STATUS>) => {
			state.challengeLoadingStatus = action.payload;
		},
		setDailyExercises: (state, action: PayloadAction<ExerciseModel[]>) => {
			state.dailyExercises = action.payload;
		},
		setExercisesLoadingState: (state, action: PayloadAction<LOADING_STATUS>) => {
			state.exercisesLoadingStatus = action.payload;
		},
		setIssues: (state, action: PayloadAction<IssueModel[]>) => {
			state.issues = action.payload;
		},
		setIssuesLoadingState: (state, action: PayloadAction<LOADING_STATUS>) => {
			state.issuesLoadingStatus = action.payload;
		},
		setPhotoTips: (state, action: PayloadAction<PhotoTipModel[]>) => {
			state.photoTips = action.payload;
		},
		setPhotoTipsLoadingState: (state, action: PayloadAction<LOADING_STATUS>) => {
			state.photoTipsLoadingStatus = action.payload;
		},
		setFirstAidKits: (state, action: PayloadAction<ExerciseModel[]>) => {
			state.firstAidKits = action.payload;
		},
		setFirstAidKitsLoadingState: (state, action: PayloadAction<LOADING_STATUS>) => {
			state.firstAidKitsLoadingStatus = action.payload;
		},
		setTrainStatus: (state, action: PayloadAction<ITrainStatus>) => {
			state.trainStatus = action.payload;
		},
		setTrainStatusLoadingState: (state, action: PayloadAction<LOADING_STATUS>) => {
			state.trainStatusLoadingStatus = action.payload;
		},
	},
});

export const {
	setScan, setSpineScan, setScanLoadingState,
	setChallenges, setChallenge, setChallengeLoadingState,
	setDailyExercises, setExercisesLoadingState,
	setIssues, setIssuesLoadingState,
	setPhotoTips, setPhotoTipsLoadingState,
	setFirstAidKits, setFirstAidKitsLoadingState,
	setTrainStatus, setTrainStatusLoadingState,
} = PatientSlice.actions;

export default PatientSlice.reducer;
