import React, {useMemo} from "react";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {InfoCircleIcon} from "../../../../icons";
import {PatientLayout} from "../../../../layouts";
import {
  PageBackButton,
  FirstAidKits,
} from "../../../../parts";
import {useSelector} from "../../../../redux/store";

export const LearnExercise = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { dailyExercises, firstAidKits } = useSelector((root) => root.patient);

  let exercise = dailyExercises.find((item) => item.id === id);
  if (!exercise) {
    exercise = firstAidKits.find((item) => item.id === id);
  }

  const content = useMemo(() => exercise?.getLocalizedContent(), [exercise]);

  const videoUrl = useMemo(() => {
    const url = content?.video || exercise?.videoVimeo;
    return `${url}?autoplay=1&controls=1&badge=0&byline=0&portrait=0&title=0&loop=1`;
  }, [content, exercise]);

  const sidebar = (
    <>
      <FirstAidKits />
    </>
  );

  return (
    <PatientLayout sidebar={sidebar}>
      <PageBackButton />

      {exercise && (
        <>
          <h1 className="mt-8">{content?.title}</h1>

          <div className="flex-center mt-6">
            <iframe
              className="w-full aspect-video"
              title={content?.title}
              src={videoUrl}
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            />
          </div>

          <p className="text-typo1 mt-6">
            {content?.description}
          </p>

          <div className="flex items-center typo-xs mt-6">
            <InfoCircleIcon className="mr-2" size={16}/>
            {t('patient.personalTrainingPlanHelperText')}.
          </div>
        </>
      )}
    </PatientLayout>
  );
};
