import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {fetchDailyExercises, fetchFirstAidKits, fetchIssues, fetchPhotoTips, fetchTrainStatus} from "../../redux/apis";
import {setChallenges} from "../../redux/reducers/patient.reducer";
import {useDispatch, useSelector} from "../../redux/store";
import {LANGUAGE, STATUS} from "../../resources/enums";
import {ChallengesService} from "../../services";

export const PatientDataLoader = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const { scan } = useSelector((state) => state.patient);

  const scanId = scan?.id;
  const scanStatus = scan?.status;
  const language = (i18n.language || 'en') as LANGUAGE;

  useEffect(() => {
    ChallengesService.search().then((data) => {
      const challenges = data.result.sort((a, b) => b.createdAt.localeCompare(a.createdAt));
      dispatch(setChallenges(challenges));
    });
  }, [dispatch]);

  useEffect(() => {
    if (scanId) {
      dispatch(fetchDailyExercises({
        query: { lang: language },
        force: true,
      }));
    }
  }, [dispatch, scanId, scanStatus, language]);

  useEffect(() => {
    if (scanId && scanStatus === STATUS.COMPLETED) {
      dispatch(fetchIssues({ query: { lang: language }, force: true }));
    }
  }, [dispatch, language, scanId, scanStatus]);

  useEffect(() => {
    dispatch(fetchPhotoTips({
      query: { lang: language },
      force: true,
    }));
  }, [dispatch, language]);

  useEffect(() => {
    dispatch(fetchFirstAidKits({
      query: { lang: language },
    }));
  }, [dispatch, language]);

  useEffect(() => {
    if (scanId && scanStatus === STATUS.COMPLETED) {
      dispatch(fetchTrainStatus({ force: true, showSpinner: false, showError: false }));
    }
  }, [scanId, scanStatus, dispatch]);

  return null;
};
