import {SUBSCRIPTION_PLAN, SUBSCRIPTION_STATUS} from "../enums";

export class SubscriptionModel {
  id: string;
  original_transaction_id: string;
  status: SUBSCRIPTION_STATUS;
  product_id: SUBSCRIPTION_PLAN;
  expires_date?: string;
  purchase_date?: string;
  updatedAt: string;

  constructor(init?: Partial<SubscriptionModel>) {
    const data = {
      id: null,
      ...init,
    };

    this.id = data.id;
    this.original_transaction_id = data.original_transaction_id;
    this.status = data.status;
    this.product_id = data.product_id;
    this.expires_date = data.expires_date;
    this.purchase_date = data.purchase_date;
    this.updatedAt = data.updatedAt;
  }
}
