import React, {FC} from "react";
import {Link} from "react-router-dom";
import classNames from "classnames";
import {Trans, useTranslation} from "react-i18next";
import {UserExerciseIcon} from "../../icons";
import {ExerciseModel} from "../../resources/models";

export interface ILearnExerciseCardProps {
  className?: string;
  exercise?: ExerciseModel;
}

export const LearnExerciseCard: FC<ILearnExerciseCardProps> = ({
  className,
  exercise,
}) => {
  const { t } = useTranslation();

  return (
    <Link
      className={classNames('card2 block px-6 py-3', className)}
      to={exercise ? `/exercises/${exercise.id}/learn` : ''}
    >
      <div className="flex items-center">
        <UserExerciseIcon className="text-typo1" size={20} />
        <h6 className="ml-3 mr-4">{t('patient.learnMoreAboutExercises')}</h6>
        <i className="fa fa-angle-right p-1 ml-auto" />
      </div>
      <p className="mt-2">
        <Trans i18nKey="patient.useAiToAnalyzePhotos" />.
      </p>
    </Link>
  );
};
