import React, {FC} from "react";
import {Icon} from "./Icon";
import {IIconProps} from "./types";

export const CheckCircleIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={24} baseHeight={24} {...props}>
    {(width, height, color) => (
      <svg width={width} height={height} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M18.2808 8.29279C18.0932 8.10532 17.8389 8 17.5738 8C17.3086 8 17.0543 8.10532 16.8668 8.29279L11.2807 13.8793L8.69472 11.2933C8.50611 11.1112 8.25351 11.0104 7.99132 11.0127C7.72912 11.0149 7.47831 11.1201 7.2929 11.3055C7.10749 11.4909 7.00232 11.7417 7.00004 12.0039C6.99777 12.2661 7.09856 12.5187 7.28072 12.7073L10.5736 16.0002C10.9641 16.3907 11.5973 16.3907 11.9878 16.0002L18.2808 9.70679C18.4682 9.51926 18.5736 9.26495 18.5736 8.99979C18.5736 8.73462 18.4682 8.48031 18.2808 8.29279Z"
        />
        <path
          fill={color}
          d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21509 0.913451 7.4078C0.00519943 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.8071 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0866C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C23.9966 8.81846 22.7312 5.76821 20.4815 3.51852C18.2318 1.26883 15.1815 0.00344108 12 0ZM12 22C10.0222 22 8.08879 21.4135 6.4443 20.3147C4.79981 19.2159 3.51809 17.6541 2.76121 15.8268C2.00433 13.9996 1.8063 11.9889 2.19215 10.0491C2.578 8.10929 3.53041 6.32746 4.92894 4.92893C6.32746 3.53041 8.10929 2.578 10.0491 2.19215C11.9889 1.8063 13.9996 2.00433 15.8268 2.7612C17.6541 3.51808 19.2159 4.79981 20.3147 6.4443C21.4135 8.08879 22 10.0222 22 12C21.9971 14.6513 20.9426 17.1931 19.0679 19.0679C17.1931 20.9426 14.6513 21.9971 12 22Z"
        />
      </svg>
    )}
  </Icon>
);
