import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {
  TypedUseSelectorHook,
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
} from "react-redux";
import authReducer from "./reducers/auth.reducer";
import spinnerReducer from "./reducers/spinner.reducer";
import onboardReducer from "./reducers/onboard.reducer";
import patientReducer from "./reducers/patient.reducer";

export type RootState = ReturnType<typeof rootReducer>;
export type Dispatch = typeof store.dispatch;
export type GetState = typeof store.getState;

const rootReducer = combineReducers({
  auth: authReducer,
  spinner: spinnerReducer,
  onboard: onboardReducer,
  patient: patientReducer,
});

export const store = configureStore<RootState>({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false,
  }) as any,
});

export const useSelector: TypedUseSelectorHook<RootState> = useAppSelector;

export const useDispatch = () => useAppDispatch<Dispatch>();
