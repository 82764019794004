import React, {FC} from "react";
import {Icon} from "./Icon";
import {IIconProps} from "./types";

export const CommentQuestionIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={24} baseHeight={24} {...props}>
    {(width, height, color) => (
      <svg width={width} height={height} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M11.903 16C12.731 16 13.403 16.672 13.403 17.5C13.403 18.328 12.731 19 11.903 19C11.075 19 10.403 18.328 10.403 17.5C10.403 16.672 11.075 16 11.903 16ZM12.903 14C12.903 13.439 13.311 12.775 13.831 12.488C15.331 11.662 16.138 9.96497 15.84 8.26497C15.557 6.65197 14.233 5.32697 12.619 5.04397C11.437 4.83997 10.239 5.15597 9.33004 5.91797C8.42304 6.68097 7.90203 7.79697 7.90203 8.98097C7.90203 9.53397 8.35003 9.98097 8.90203 9.98097C9.45403 9.98097 9.90203 9.53397 9.90203 8.98097C9.90203 8.38897 10.162 7.83097 10.616 7.44897C11.077 7.06297 11.668 6.90697 12.273 7.01397C13.06 7.15197 13.731 7.82397 13.869 8.60997C14.022 9.48097 13.628 10.315 12.865 10.735C11.709 11.372 10.902 12.714 10.902 13.999C10.902 14.552 11.35 14.999 11.902 14.999C12.454 14.999 12.902 14.552 12.902 13.999L12.903 14ZM23.999 19V12.34C23.999 5.86097 19.095 0.453971 12.835 0.0279705C9.36003 -0.20203 5.96003 1.06597 3.50803 3.52097C1.05604 5.97697 -0.211965 9.37797 0.029035 12.854C0.469035 19.208 6.08103 24 13.083 24H19C21.757 24 24 21.757 24 19H23.999ZM12.7 2.02397C17.915 2.37797 21.999 6.90897 21.999 12.339V18.999C21.999 20.653 20.653 21.999 18.999 21.999H13.082C7.04704 21.999 2.39603 18.095 2.02303 12.715C1.82203 9.81597 2.87803 6.97997 4.92203 4.93397C6.80403 3.04897 9.35703 1.99997 12.014 1.99997C12.242 1.99997 12.471 2.00797 12.699 2.02297L12.7 2.02397Z"
        />
      </svg>
    )}
  </Icon>
);
