import React, {FC} from "react";
import {Icon} from "./Icon";
import {IIconProps} from "./types";

export const PlayIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={48} baseHeight={48} {...props}>
    {(width, height, color) => (
      <svg width={width} height={height} viewBox="0 0 48 48">
        <path
          fill={color}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.9392 22.0125L19.1452 12.5935C19.1448 12.5933 19.1456 12.5938 19.1452 12.5935C18.6708 12.2706 18.082 12.059 17.4446 12.0106C16.8065 11.9621 16.1769 12.081 15.6318 12.3381C15.0881 12.5946 14.6715 12.9673 14.3984 13.3808C14.1276 13.7909 14.0003 14.2348 14 14.6657V33.3329C14 33.3327 14 33.3332 14 33.3329C14.0002 33.764 14.1275 34.209 14.3984 34.6192C14.6716 35.0329 15.0885 35.4057 15.6326 35.6622C16.178 35.9194 16.808 36.038 17.4464 35.9893C18.0842 35.9405 18.672 35.7292 19.1463 35.4057C19.1461 35.4058 19.1466 35.4055 19.1463 35.4057L32.8166 26.0712C33.2182 25.7971 33.5181 25.4574 33.7133 25.0988C33.9076 24.742 34 24.3659 34 23.9979C34 23.6298 33.9076 23.2538 33.7133 22.897C33.5383 22.5756 33.28 22.2698 32.9392 22.0125ZM35.0723 18.6212C35.9797 19.2405 36.7176 20.05 37.2263 20.9841C37.7349 21.9182 38 22.9507 38 23.9979C38 25.0451 37.7349 26.0775 37.2263 27.0117C36.7176 27.9458 35.9797 28.7553 35.0723 29.3745L21.402 38.709C20.3344 39.4377 19.0707 39.8768 17.7512 39.9776C16.4317 40.0785 15.1079 39.8371 13.9269 39.2803C12.7458 38.7235 11.7537 37.8731 11.0605 36.8234C10.3673 35.7737 10.0002 34.5658 10 33.3337V14.6647C10.0006 13.4332 10.3676 12.2259 11.0605 11.1767C11.7533 10.1274 12.7449 9.27727 13.9253 8.72045C15.1056 8.16363 16.4286 7.92192 17.7476 8.0221C19.0666 8.12229 20.33 8.56047 21.3977 9.28804L35.068 18.6225L35.0723 18.6212Z"
        />
      </svg>
    )}
  </Icon>
);
