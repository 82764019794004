export class KeypointModel {
  LEFT_ANKLE: number[];
  RIGHT_ANKLE: number[];
  LEFT_EAR: number[];
  RIGHT_EAR: number[];
  LEFT_ELBOW: number[];
  RIGHT_ELBOW: number[];
  LEFT_EYE: number[];
  RIGHT_EYE: number[];
  LEFT_HIP: number[];
  RIGHT_HIP: number[];
  LEFT_KNEE: number[];
  RIGHT_KNEE: number[];
  LEFT_SHOULDER: number[];
  RIGHT_SHOULDER: number[];
  LEFT_WRIST: number[];
  RIGHT_WRIST: number[];
  NOSE: number[];

  constructor(init?: Partial<KeypointModel>) {
    const data = {
      ...init,
    };

    this.LEFT_ANKLE = data.LEFT_ANKLE;
    this.RIGHT_ANKLE = data.RIGHT_ANKLE;
    this.LEFT_EAR = data.LEFT_EAR;
    this.RIGHT_EAR = data.RIGHT_EAR;
    this.LEFT_ELBOW = data.LEFT_ELBOW;
    this.RIGHT_ELBOW = data.RIGHT_ELBOW;
    this.LEFT_EYE = data.LEFT_EYE;
    this.RIGHT_EYE = data.RIGHT_EYE;
    this.LEFT_HIP = data.LEFT_HIP;
    this.RIGHT_HIP = data.RIGHT_HIP;
    this.LEFT_KNEE = data.LEFT_KNEE;
    this.RIGHT_KNEE = data.RIGHT_KNEE;
    this.LEFT_SHOULDER = data.LEFT_SHOULDER;
    this.RIGHT_SHOULDER = data.RIGHT_SHOULDER;
    this.LEFT_WRIST = data.LEFT_WRIST;
    this.RIGHT_WRIST = data.RIGHT_WRIST;
    this.NOSE = data.NOSE;
  }
}

export class KeypointFrontModel extends KeypointModel {
  LEFT_FOOT: number[];
  RIGHT_FOOT: number[];

  constructor(init?: Partial<KeypointFrontModel>) {
    super(init);

    const data = {
      ...init,
    };

    this.LEFT_FOOT = data.LEFT_FOOT;
    this.RIGHT_FOOT = data.RIGHT_FOOT;
  }
}

export class KeypointSideModel extends KeypointFrontModel {
  LEFT_EYE_INNER: number[];
  LEFT_EYE_OUTER: number[];
  RIGHT_EYE_INNER: number[];
  RIGHT_EYE_OUTER: number[];
  LEFT_MOUTH: number[];
  RIGHT_MOUTH: number[];
  LEFT_PINKY: number[];
  RIGHT_PINKY: number[];
  LEFT_INDEX: number[];
  RIGHT_INDEX: number[];
  LEFT_THUMB: number[];
  RIGHT_THUMB: number[];
  LEFT_HEEL: number[];
  RIGHT_HEEL: number[];

  constructor(init?: Partial<KeypointSideModel>) {
    super(init);

    const data = {
      ...init,
    };

    this.LEFT_EYE_INNER = data.LEFT_EYE_INNER;
    this.LEFT_EYE_OUTER = data.LEFT_EYE_OUTER;
    this.RIGHT_EYE_INNER = data.RIGHT_EYE_INNER;
    this.RIGHT_EYE_OUTER = data.RIGHT_EYE_OUTER;
    this.LEFT_MOUTH = data.LEFT_MOUTH;
    this.RIGHT_MOUTH = data.RIGHT_MOUTH;
    this.LEFT_PINKY = data.LEFT_PINKY;
    this.RIGHT_PINKY = data.RIGHT_PINKY;
    this.LEFT_INDEX = data.LEFT_INDEX;
    this.RIGHT_INDEX = data.RIGHT_INDEX;
    this.LEFT_THUMB = data.LEFT_THUMB;
    this.RIGHT_THUMB = data.RIGHT_THUMB;
    this.LEFT_HEEL = data.LEFT_HEEL;
    this.RIGHT_HEEL = data.RIGHT_HEEL;
  }
}

export class FeaturePointsModel {
  neck: number[];
  ka: number[];
  la: number[];
  neck_infl: number[];
  ict: number[];
  itl: number[];
  ils: number[];

  constructor(init?: Partial<FeaturePointsModel>) {
    const data = {
      ...init,
    };

    this.neck = data.neck;
    this.ka = data.ka;
    this.la = data.la;
    this.neck_infl = data.neck_infl;
    this.ict = data.ict;
    this.itl = data.itl;
    this.ils = data.ils;
  }
}

export class ScanPointsModel {
  front: KeypointFrontModel;
  side: KeypointSideModel;
  sit: KeypointModel;
  feature: FeaturePointsModel;
  line: [number, number][];

  constructor(init?: Partial<ScanPointsModel>) {
    const data = {
      ...init,
    };

    this.front = new KeypointFrontModel(data.front);
    this.side = new KeypointSideModel(data.side);
    this.sit = new KeypointModel(data.sit);
    this.feature = new FeaturePointsModel(data.feature);
    this.line = data.line;
  }
}
