import React, {FC, PropsWithChildren, useEffect, useRef} from "react";

export interface ClickAwayListenerProps extends PropsWithChildren {
  className?: string;
  onClick: VoidFunction;
}

export const ClickAwayListener: FC<ClickAwayListenerProps> = ({
  className,
  onClick,
  children,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const onClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClick();
      }
    };

    document.addEventListener('click', onClickOutside);

    return () => {
      document.removeEventListener('click', onClickOutside);
    };
  }, [onClick]);

  return (
    <div className={className} ref={ref}>
      {children}
    </div>
  );
};
