import React, {FC, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {IssueCard} from "../IssueCard";
import {HealthIcon} from "../../icons";
import {useSelector} from "../../redux/store";

export interface IHealthIssuesProps {
  className?: string;
}

export const HealthIssues: FC<IHealthIssuesProps> = ({
  className,
}) => {
  const { t } = useTranslation();
  const { issues } = useSelector((root) => root.patient);

  const topIssues = useMemo(() => (
    issues.slice(0, 3)
  ), [issues]);

  return (
    <div className={className}>
      {!issues.length ? (
        <div className="card1 px-4 py-3">
          <div className="flex items-center">
            <HealthIcon className="text-typo1" size={24} />
            <h6 className="ml-3">{t('patient.healthIssues.noIssues')}</h6>
          </div>
          <p className="mt-2">
            {t('patient.healthIssues.workoutPrepared')}.
          </p>
        </div>
      ) : (
        topIssues.map((item) => (
          <IssueCard key={item.id} className="mb-2" issue={item} />
        ))
      )}
    </div>
  );
};
