import {HttpService} from "./http.service";
import {
  CreateScanRequestDto,
  PaginateRequestDto,
  SearchScansRequestDto
} from "../resources/dtos";
import {LANGUAGE, PHOTO_TYPE} from "../resources/enums";
import {ExerciseModel, IssueModel, ScanModel} from "../resources/models";
import {formatPaginatedResult, getCurrentLanguage} from "../utils/helpers";
import {CONFIG} from "../constants";
import {ITrainStatus} from "../resources/interfaces";

export class ScansService {

  static search(query: SearchScansRequestDto, hasSpinner = true) {
    return HttpService.get('/scans', query, hasSpinner)
       .then((result) => formatPaginatedResult<ScanModel>(ScanModel, result.data));
  }

  static find(id: string, hasSpinner = true): Promise<ScanModel> {
    return HttpService.get(`/scans/${id}`, {}, hasSpinner)
      .then((result) => new ScanModel(result.data));
  }

  static create(data: CreateScanRequestDto, hasSpinner = true): Promise<ScanModel> {
    return HttpService.post('/scans', data, hasSpinner)
      .then((result) => new ScanModel(result.data));
  }

  static uploadPhoto(id: string, type: PHOTO_TYPE, file: Blob, hasSpinner = true): Promise<ScanModel> {
    const formData = new FormData();
    formData.append('file', file);

    return HttpService.patch(`/scans/${id}/${type}`, formData, hasSpinner)
      .then((result) => new ScanModel(result.data));
  }

  static analyze(id: string, hasSpinner = true) {
    return HttpService.get(`/scans/${id}/analyze`, {}, hasSpinner)
      .then((result) => new ScanModel(result.data));
  }

  static searchExercises(scanId: string, query: PaginateRequestDto = {}, hasSpinner = true) {
    return HttpService.get(`/scans/${scanId}/exercises`, query, hasSpinner)
      .then((result) => formatPaginatedResult<ExerciseModel>(ExerciseModel, result.data));
  }

  static getDailyExercises(scanId: string, query?: { lang: LANGUAGE }, hasSpinner = true): Promise<ExerciseModel[]> {
    return HttpService.get(`/scans/${scanId}/daily-exercises`, query, hasSpinner)
      .then((res) => res.data.map((item) => new ExerciseModel(item)));
  }

  static getTrainStatus(scanId: string, hasSpinner = true): Promise<ITrainStatus> {
    return HttpService.get(`/scans/${scanId}/train-status`, {}, hasSpinner)
      .then((res) => res.data);
  }

  static searchIssues(scanId: string, query: PaginateRequestDto = {}, hasSpinner = true) {
    return HttpService.get(`/scans/${scanId}/issues`, query, hasSpinner)
      .then((result) => formatPaginatedResult<IssueModel>(IssueModel, result.data));
  }

  static searchFeatures(scanId: string, query: PaginateRequestDto = {}, hasSpinner = true) {
    return HttpService.get(`/scans/${scanId}/features`, query, hasSpinner)
      .then((result) => formatPaginatedResult<IssueModel>(IssueModel, result.data));
  }

  static exportReportAsPdf(hasSpinner = true): Promise<Blob> {
    return HttpService.request(
      'GET',
      `/scans/report/pdf`,
      {
        params: {
          theme: CONFIG.THEME,
          language: getCurrentLanguage(),
        },
        responseType: 'blob',
      },
      hasSpinner,
    );
  }

  static shareScan(scanId: string, email: string, hasSpinner = true): Promise<void> {
    return HttpService.post(`/scans/${scanId}/share`, { email }, hasSpinner);
  }
}
