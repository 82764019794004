import React, {FC} from "react";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import {buildStyles, CircularProgressbar} from "react-circular-progressbar";
import {useSelector} from "../../redux/store";

export interface IPostureLevelProps {
  className?: string;
}

export const PostureLevel: FC<IPostureLevelProps> = ({
  className,
}) => {
  const { t } = useTranslation();
  const { scan } = useSelector((root) => root.patient);

  const postureScore = Math.round(scan.healthScore * 100);

  return (
    <div className={classNames('relative flex items-center', className)}>
      <CircularProgressbar
        className="w-22"
        value={postureScore}
        text={`${postureScore}%`}
        strokeWidth={6}
        styles={buildStyles({
          rotation: 0.5,
          strokeLinecap: 'round',
          textSize: '1.25rem',
        })}
      />

      <h1 className="ml-8">{t('patient.yourPostureScore')}</h1>
    </div>
  );
};
