import React, {FC, InputHTMLAttributes, ReactNode, useState} from "react";
import classNames from "classnames";

export interface ITextFieldProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size' | 'onChange'> {
  fullWidth?: boolean;
  variant?: 'default' | 'filled',
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  value?: string | number;
  label?: ReactNode;
  labelClass?: string;
  inputClass?: string;
  helperText?: ReactNode;
  helperClass?: string;
  error?: boolean;
  onChange?: (value: string) => void;
}

export const TextField: FC<ITextFieldProps> = ({
  className,
  fullWidth,
  variant = 'default',
  size = 'md',
  value,
  label,
  labelClass,
  inputClass,
  helperText,
  helperClass,
  error,
  disabled,
  onChange,
  ...inputProps
}) => {
  const [focused, setFocused] = useState(false);

  const labelFloated = focused || value;

  const wrapperClassName = classNames(
    'relative',
    fullWidth && 'w-full',
    size === 'md' && 'typo-md',
    className,
  );

  const inputClassName = classNames(
    'w-full font-semibold border outline-none transition-all',
    disabled ? 'bg-white/16 text-white/48 border-white/32' : [
      'bg-input-bg text-input-text',
      error ? 'border-danger' : 'border-input-border focus:border-input-border-focus',
    ],
    size === 'md' && [
      'rounded-lg',
      variant === 'filled' ? 'min-h-17 pt-7 pb-1 px-4' : 'min-h-12 px-4 py-3',
    ],
    inputClass,
  );

  const labelClassName = classNames(
    'transition-all',
    variant === 'default' && 'text-[87.5%] px-1 mb-1',
    variant === 'filled' && [
      'absolute left-0 pointer-events-none',
      labelFloated ? 'text-[87.5%]' : 'text-[125%]',
      size === 'md' && [
        'w-full px-4',
        variant === 'filled' && !labelFloated ? 'top-5.5' : 'top-3.5',
      ],
    ],
    labelClass,
  );

  const helperClassName = classNames(
    'mt-1',
    helperClass,
  );

  const onInputChange = (value: string) => {
    onChange && onChange(value);
  };

  return (
    <div className={wrapperClassName}>
      {label && (
        <div className={labelClassName}>{label}</div>
      )}
      <input
        className={inputClassName}
        value={value}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onChange={(e) => onInputChange(e.target.value)}
        {...inputProps}
      />
      {helperText && (
        <div className={helperClassName}>{helperText}</div>
      )}
    </div>
  );
};
