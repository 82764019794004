import React, {FC, MouseEventHandler} from "react";
import {useNavigate} from "react-router-dom";
import classNames from "classnames";
import {Image} from "../../components";
import {ExerciseModel} from "../../resources/models";

export interface IExerciseCardProps {
  className?: string;
  exercise: ExerciseModel;
  active: boolean;
  onClick(): void;
}

export const ExerciseCard: FC<IExerciseCardProps> = ({
  className,
  exercise,
  active,
  onClick,
}) => {
  const navigate = useNavigate();

  const isLocked = !active && !exercise.scan?.doneAt;

  const onView = () => {
    if (!active) {
      return;
    }

    onClick();
  };

  const onViewInfo: MouseEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    navigate(`/exercises/${exercise.id}/learn`);
  };

  return (
    <div className={classNames('card1 h-22 flex', active && 'cursor-pointer', className)} onClick={onView}>
      <Image
        wrapperClass="!w-auto h-full bg-black shrink-0"
        src={exercise.getCover()?.src}
        aspectRatio={1}
        spinner
        spinnerSize="sm"
      />
      <div className="flex-1 flex items-center justify-between px-4 py-2">
        <div className="max-w-36 typo-sm mr-4">{exercise.getLocalizedContent()?.title || ''}</div>
        {active ? (
          <i className="fa fa-info-circle text-primary text-2xl" onClick={onViewInfo} />
        ) : isLocked ? (
          <i className="fa fa-lock text-secondary text-2xl" />
        ) : (
          <i className="fa fa-check-circle text-secondary text-2xl" />
        )}
      </div>
    </div>
  );
};
