import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import {PatientHome} from "./Home";
import {ExercisesRouting} from "./Exercises/routing";
import {ScannerRouting} from "./Scanner/routing";
import {MyAccount} from "./MyAccount";
import {PatientDataLoader} from "../../parts";

export const PatientRouting = () => {
  return (
    <>
      <PatientDataLoader />

      <Routes>
        <Route path="/home" element={<PatientHome />} />
        <Route path="/exercises/*" element={<ExercisesRouting />} />
        <Route path="/scanner/*" element={<ScannerRouting />} />
        <Route path="/my-account" element={<MyAccount />} />
        <Route path="*" element={<Navigate to="/home" />} />
      </Routes>
    </>
  );
};

