import moment from "moment";
import {MouseEventHandler} from "react";

export const ignoreEvent: MouseEventHandler = (e) => {
  // e.preventDefault();
  e.stopPropagation();
};

export const isToday = (date: string | number | Date) => {
  if (!date) {
    return false;
  }

  return moment(date).isSame(moment(), 'day');
};

export const isInDays = (date: string | number | Date, days: number) => {
  if (!date) {
    return false;
  }

  return moment(date).isSameOrAfter(moment().subtract(days, 'days'), 'day');
};
