import classNames from "classnames";
import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {LANGUAGES_LOCALIZATION} from "../../../constants/localization";
import {useQueryValue} from "../../../hooks";
import {ExerciseModel} from "../../../resources/models";
import {ExercisesService, ToastService} from "../../../services";
import {LANGUAGE} from "../../../resources/enums";

const languages = [LANGUAGE.EN, LANGUAGE.DE];

export const ExerciseVideo = () => {
  const { t } = useTranslation();

  const key = useQueryValue('key');
  const lang = useQueryValue('language');
  const [exercise, setExercise] = useState<ExerciseModel>();
  const [loading, setLoading] = useState(true);
  const [currentLanguage, setCurrentLanguage] = useState(LANGUAGE.DE);

  const content = useMemo(() => (
    exercise?.getContentByLanguage(currentLanguage)
  ), [exercise, currentLanguage]);

  const videoUrl = useMemo(() => {
    const url = content?.video || exercise?.videoVimeo;
    return `${url}?autoplay=1&controls=1&badge=0&byline=0&portrait=0&title=0&loop=1`;
  }, [content, exercise]);

  const supportLanguages = useMemo(() => {
    if (!exercise) {
      return languages;
    }
    return languages.filter((lang) => (
      exercise.content.some((content) => content.language === lang)
    ));
  }, [exercise]);

  useEffect(() => {
    if (!key) {
      setLoading(false);
      setExercise(undefined);
      return;
    }

    setLoading(true);
    ExercisesService.findByKey(key).then((data) => {
      setExercise(data);
    }).catch((err) => {
      ToastService.showHttpError(err, 'toast.loadingExerciseVideoFailed');
    }).finally(() => {
      setLoading(false);
    });
  }, [key]);

  useEffect(() => {
    if (lang) {
      const language = lang.toLowerCase() as LANGUAGE;
      if (supportLanguages.includes(language)) {
        setCurrentLanguage(language);
      } else {
        setCurrentLanguage(LANGUAGE.EN);
      }
    } else {
      setCurrentLanguage(LANGUAGE.DE);
    }
  }, [lang, supportLanguages]);

  const onChanges = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();
    setCurrentLanguage(event.target.value as LANGUAGE);
  };

  return (
    <div className="min-h-screen flex-center bg-onboard1 bg-cover bg-center p-4">
      {!loading && (
        exercise ? (
          <>
            <div className="max-w-225">
              <div className="w-25 mb-2">
                <select
                  className="form-control form-control-sm text-black"
                  defaultValue={currentLanguage}
                  onChange={(e) => onChanges(e)}
                >
                  {supportLanguages.map((language, i) => (
                    <option
                      key={i}
                      value={language}
                      className={classNames('cursor-pointer', language === currentLanguage && 'text-primary')}
                    >
                      {LANGUAGES_LOCALIZATION[language] || language}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <iframe
                  className="w-full aspect-video border-0"
                  title={content?.title}
                  src={videoUrl}
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                />
                <div className="bg-stroke1 p-6 -mt-2">
                  <h2>{content?.title}</h2>
                  <p className="text-white mt-2">
                    {content?.description}
                  </p>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="max-w-225">
            {t('toast.exerciseVideoNotAvailable')}
          </div>
        )
      )}
    </div>
  );
};
