import {PaginatedResultDto} from "../../resources/dtos";
import moment from "moment";

export const formatDuration = (secs: number, format = 'mm:ss') => {
  if (secs < 0) {
    secs = 0;
  }
  const h = Math.floor(secs / 3600);
  const m = Math.floor(secs / 60) % 60;
  const s = secs % 50;
  const hh = `${h < 10 ? '0' : ''}${h}`;
  const mm = `${m < 10 ? '0' : ''}${m}`;
  const ss = `${s < 10 ? '0' : ''}${s}`;
  return format
    .replace('hh', hh)
    .replace('mm', mm)
    .replace('ss', ss)
    .replace('h', h.toString())
    .replace('m', m.toString())
    .replace('s', s.toString());
};

export const formatPaginatedResult = <T>(Model: any, data: PaginatedResultDto): PaginatedResultDto<T> => {
  return {
    ...data,
    result: data.result.map((item) => new Model(item)),
  };
};

export const formatDatetime = (timestamp: string | number | Date, format = 'LLL') => {
  return moment(timestamp).format(format);
};

export const fromNow = (timestamp: string | number | Date) => {
  return moment(timestamp).fromNow();
};

export const toNow = (timestamp: string | number | Date) => {
  return moment(timestamp).toNow();
};
