import {HttpService} from "./http.service";
import {PaginateRequestDto} from "../resources/dtos";
import {ChallengeModel} from "../resources/models";
import {formatPaginatedResult} from "../utils/helpers";

export class ChallengesService {

  static search(query: PaginateRequestDto = {}, hasSpinner = true) {
    return HttpService.get('/challenges', query, hasSpinner)
      .then((result) => formatPaginatedResult<ChallengeModel>(ChallengeModel, result.data));
  }

  static find(id: string, hasSpinner = true): Promise<ChallengeModel> {
    return HttpService.get(`/challenges/${id}`, {}, hasSpinner)
      .then((result) => new ChallengeModel(result.data));
  }

  static create(hasSpinner = true): Promise<ChallengeModel> {
    return HttpService.post('/challenges', {}, hasSpinner)
      .then((result) => new ChallengeModel(result.data));
  }
}
