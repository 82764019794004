import React, {FC, useState} from "react";
import classNames from "classnames";
import {TimerIcon} from "../../icons";

export interface ICameraTimerProps {
  className?: string;
  options: number[];
  value?: number;
  onChanges(value: number): void;
}

export const CameraTimer: FC<ICameraTimerProps> = ({
  className,
  options,
  value,
  onChanges,
}) => {
  const [opened, setOpened] = useState(false);

  const cameraTimerClass = classNames(
    'relative h-12 flex items-center justify-end',
    'bg-black/40 rounded-3xl pl-12 overflow-hidden transition-all',
    opened ? 'max-w-41' : 'max-w-12',
    className,
  );

  const cameraOptionsClass = classNames(
    'flex items-center px-2 transition-all',
    !opened && 'opacity-0',
  );

  return (
    <div className={cameraTimerClass}>
      <div className="absolute left-0 z-1 w-12 h-12 flex-center bg-overlay1 text-primary rounded-3xl cursor-pointer transition-all" onClick={() => setOpened(!opened)}>
        <TimerIcon />
      </div>
      <div className={cameraOptionsClass}>
        {options.map((option, i) => (
          <div
            key={i}
            className={classNames(
              'typo-button !leading-none px-4 cursor-pointer transition-all',
              option === value ? 'text-primary' : 'text-white',
              i > 0 && 'border-l border-white',
            )}
            onClick={() => onChanges(option)}
          >
            {option}S
          </div>
        ))}
      </div>
    </div>
  );
};
