import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {PhotoScanner} from "../../../../components";
import {PatientLayout} from "../../../../layouts";
import {PageBackButton} from "../../../../parts";
import {getAccount} from "../../../../redux/selectors";
import {useSelector} from "../../../../redux/store";
import {PHOTO_TYPE, PHOTO_TYPE_VALUES} from "../../../../resources/enums";
import {IPhoto} from "../../../../resources/interfaces";
import {ScanModel} from "../../../../resources/models";
import {ScansService, ToastService} from "../../../../services";
import {getPhotoTypeText} from "../../../../utils/helpers";

export const PhotoScan = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const user = useSelector(getAccount);
  const { id, type } = useParams<{ id: string, type: PHOTO_TYPE }>();
  const [scan, setScan] = useState<ScanModel>();

  useEffect(() => {
    if (!id) {
      navigate('/scanner', { replace: true });
      return;
    }

    ScansService.find(id).then((data) => {
      setScan(data);
    }).catch(() => {
      ToastService.error('toast.loadingScanFailed');
      navigate('/scanner', { replace: true });
    });
  }, [id, navigate]);

  const onUpload = (photo: IPhoto) => {
    if (!scan) {
      return;
    }

    ScansService.uploadPhoto(scan.id, type, photo.blob).then(() => {
      ToastService.success('toast.photoUploaded');

      const index = PHOTO_TYPE_VALUES.indexOf(type);
      if (index < 2) {
        navigate(`/scanner/${scan.id}/photos/${PHOTO_TYPE_VALUES[index + 1]}`);
      } else {
        navigate('/scanner');
      }
    }).catch((err) => {
      ToastService.showHttpError(err, 'toast.uploadingPhotoFailed');
    });
  };

  const sidebar = (
    <>
      <h2>{t('patient.photoTips')}</h2>
      <div className="m-auto">
        <img className="max-w-full" src={`/assets/images/photo-tips/${type}-${user.gender?.toLowerCase()}.png`} alt="" />
      </div>
    </>
  );

  return (
    <PatientLayout className="!p-0" sidebar={sidebar} sidebarClass="flex flex-col">
      <div className="relative h-screen">
        <PhotoScanner
          key={`${id}-${type}`}
          className="w-full h-full"
          header={(
            <>
              <PageBackButton />
              <h1 className="ml-auto">{t(getPhotoTypeText(type))}</h1>
            </>
          )}
          onTakePhoto={onUpload}
        />
      </div>
    </PatientLayout>
  );
};
