import React, {FC} from "react";
import classNames from "classnames";
import {useTranslation} from "react-i18next";

export interface IScanningLoaderProps {
  className?: string;
}

export const ScanningLoader: FC<IScanningLoaderProps> = ({
  className,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classNames('flex flex-col items-center justify-evenly bg-[#061115] text-center', className)}>
      <h1>{t('patient.scanningInProgress')}</h1>
      <div className="w-full max-w-86">
        <img className="w-full mt-8" src="/assets/images/patient/scanning.gif" alt="" />
      </div>
    </div>
  );
};
