import React from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Button} from "../../../../components";
import {PatientLayout} from "../../../../layouts";
import {
  PageBackButton,
  ExerciseChallengeCard,
  FirstAidKits,
  LearnExerciseCard,
  HowToWinChallenge,
} from "../../../../parts";
import {useSelector} from "../../../../redux/store";

export const Challenge = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { challenge } = useSelector((state) => state.patient);

  const onStart = async () => {
    navigate('/scanner');
  };

  const sidebar = (
    <>
      <LearnExerciseCard />
      <FirstAidKits className="mt-6" />
    </>
  );

  return (
    <PatientLayout sidebar={sidebar}>
      <PageBackButton />

      <h1 className="xl:w-1/2 mt-8">{t('patient.howToWinChallenge')}</h1>

      <div className="challenge-section flex max-xl:flex-col gap-6 mt-6">
        <ExerciseChallengeCard className="w-120" />

        <div>
          <HowToWinChallenge />

          {!challenge && (
            <Button className="mt-6" fullWidth onClick={onStart}>
              {t('patient.startChallenge')}
            </Button>
          )}
        </div>
      </div>
    </PatientLayout>
  );
};
