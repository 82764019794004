import React, {FC} from "react";
import {Icon} from "./Icon";
import {IIconProps} from "./types";

export const HealthIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={24} baseHeight={24} {...props}>
    {(width, height, color) => (
      <svg width={width} height={height} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M9 12C10.1867 12 11.3467 11.6481 12.3334 10.9888C13.3201 10.3295 14.0892 9.39246 14.5433 8.2961C14.9974 7.19975 15.1162 5.99335 14.8847 4.82946C14.6532 3.66558 14.0818 2.59648 13.2426 1.75736C12.4035 0.918247 11.3344 0.346802 10.1705 0.115291C9.00666 -0.11622 7.80026 0.00259972 6.7039 0.456726C5.60754 0.910851 4.67047 1.67989 4.01118 2.66658C3.35189 3.65328 3 4.81331 3 6C3.00159 7.59081 3.63424 9.11602 4.75911 10.2409C5.88399 11.3658 7.40919 11.9984 9 12ZM9 2C9.79113 2 10.5645 2.2346 11.2223 2.67412C11.8801 3.11365 12.3928 3.73836 12.6955 4.46927C12.9983 5.20017 13.0775 6.00444 12.9231 6.78036C12.7688 7.55629 12.3878 8.26902 11.8284 8.82843C11.269 9.38784 10.5563 9.7688 9.78036 9.92314C9.00444 10.0775 8.20017 9.99827 7.46927 9.69552C6.73836 9.39277 6.11365 8.88008 5.67412 8.22228C5.2346 7.56449 5 6.79113 5 6C5 4.93914 5.42143 3.92172 6.17157 3.17158C6.92172 2.42143 7.93913 2 9 2Z"
        />
        <path
          fill={color}
          d="M9 14C6.61395 14.0029 4.32645 14.9521 2.63925 16.6393C0.952057 18.3265 0.00291096 20.6139 0 23C0 23.2652 0.105357 23.5196 0.292893 23.7071C0.48043 23.8946 0.734784 24 1 24C1.26522 24 1.51957 23.8946 1.70711 23.7071C1.89464 23.5196 2 23.2652 2 23C2 21.1435 2.7375 19.363 4.05025 18.0503C5.36301 16.7375 7.14348 16 9 16C10.8565 16 12.637 16.7375 13.9497 18.0503C15.2625 19.363 16 21.1435 16 23C16 23.2652 16.1054 23.5196 16.2929 23.7071C16.4804 23.8946 16.7348 24 17 24C17.2652 24 17.5196 23.8946 17.7071 23.7071C17.8946 23.5196 18 23.2652 18 23C17.9971 20.6139 17.0479 18.3265 15.3607 16.6393C13.6735 14.9521 11.3861 14.0029 9 14Z"
        />
        <path
          fill={color}
          d="M22 7.875C21.4435 7.90273 20.9206 8.14977 20.5458 8.56207C20.171 8.97438 19.9747 9.51836 20 10.075C20.0253 9.51836 19.829 8.97438 19.4542 8.56207C19.0794 8.14977 18.5565 7.90273 18 7.875C17.4435 7.90273 16.9206 8.14977 16.5458 8.56207C16.171 8.97438 15.9747 9.51836 16 10.075C16 11.805 18.256 13.832 19.38 14.734C19.5559 14.8749 19.7746 14.9516 20 14.9516C20.2254 14.9516 20.4441 14.8749 20.62 14.734C21.744 13.834 24 11.805 24 10.075C24.0253 9.51836 23.829 8.97438 23.4542 8.56207C23.0794 8.14977 22.5565 7.90273 22 7.875Z"
        />
      </svg>
    )}
  </Icon>
);
