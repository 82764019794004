import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {debounce} from "lodash";
import classNames from "classnames";
import {CONFIG} from "../../../constants";
import {Button, Select, TextField} from "../../../components";
import {PatientLayout} from "../../../layouts";
import {allLangs} from "../../../locales/config";
import {
  DeleteAccountPopup,
  DoctorSupportCard,
  ExportScanReportPdfButton,
  GenderSelect,
  HealthierPostureIntroduction,
  HealthIssues,
  PageBackButton,
  ScanPhotosView,
  SharePhotosButton,
} from "../../../parts";
import {useDispatch, useSelector} from "../../../redux/store";
import {patchAccount, setToken} from "../../../redux/reducers/auth.reducer";
import {STATUS, SUBSCRIPTION_STATUS} from "../../../resources/enums";
import {UserModel} from "../../../resources/models";
import {AuthService, ToastService} from "../../../services";
import {formatDatetime} from "../../../utils/helpers";

const updateProfile = debounce((data: Partial<UserModel>) => {
  AuthService.updateProfile(data, false).catch((err) => {
    ToastService.showHttpError(err, 'toast.saveChangesFailed');
  });
}, 1000);

export const MyAccount = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const { account } = useSelector((root) => root.auth);
  const { scan } = useSelector((root) => root.patient);
  const [formData, setFormData] = useState({
    name: account.name,
    birth: account.birth,
    gender: account.gender,
    language: account.language,
  });
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const scanReady = scan?.status === STATUS.COMPLETED;
  const currentLanguage = allLangs.find((item) => item.value === formData.language);
  const isExpired = !account.subscription?.expires_date || moment(account.subscription.expires_date).isAfter(moment());

  const { privacyPolicyUrl, tocUrl } = useMemo(() => {
    switch (CONFIG.THEME) {
      case 'imed':
        return {
          privacyPolicyUrl: 'https://imedcoach.com/privacypolicy',
          tocUrl: 'https://imedcoach.com/termsofuse',
        };

      default:
        return {
          privacyPolicyUrl: 'https://yourposture.app/privacy-policy',
          tocUrl: 'https://yourposture.app/terms-of-use',
        };
    }
  }, []);

  useEffect(() => {
    updateProfile(formData);
    dispatch(patchAccount(formData));
  }, [dispatch, formData]);

  const onFormFieldChange = (field: string, value: string) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };
  const onChangeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    onFormFieldChange('language', lang);
  };

  const onDeleteAccount = () => {
    AuthService.deleteProfile().then(() => {
      ToastService.success('toast.profileDeleted');
      setShowDeletePopup(false);
      dispatch(setToken(null));
    }).catch((err) => {
      ToastService.showHttpError(err, 'toast.deletingProfileFailed');
    });
  };

  const sidebar = !scanReady ? (
    <div>
      <DoctorSupportCard />
      <HealthierPostureIntroduction className="mt-6" />
    </div>
  ) : (
    <div>
      <div className="flex-center">
        <h2>{t('patient.yourAnalyzedPhotos')}</h2>
        <SharePhotosButton className="!h-auto !text-primary !p-0 ml-auto" scan={scan} />
      </div>

      <ScanPhotosView className="mt-4" scan={scan} link />

      <h6 className="mt-5">{t('patient.detectedIssues')}</h6>
      <HealthIssues className="mt-4" />

      <ExportScanReportPdfButton className="mt-4" fullWidth />
    </div>
  );

  return (
    <PatientLayout sidebar={sidebar}>
      <div className="max-w-178">
        <div className="flex items-center">
          <PageBackButton />

          <h1 className="mx-auto">{t('common.profile')}</h1>
        </div>

        <h2 className="mt-6 mb-4">{t('common.accountSettings')}</h2>
        <div className="max-lg:max-w-86 grid lg:grid-cols-2 gap-x-6 gap-y-4">
          <TextField
            label={t('common.nickname')}
            fullWidth
            value={formData.name}
            onChange={(value) => onFormFieldChange('name', value)}
          />
          <TextField
            label={t('common.birthYear')}
            type="number"
            fullWidth
            value={formData.birth}
            onChange={(value) => onFormFieldChange('birth', value)}
          />
        </div>

        <div className="mt-4">
          <div className="text-sm px-1 mb-1">{t('common.whatsYourGender')}</div>
          <GenderSelect
            value={formData.gender}
            onChange={(value) => onFormFieldChange('gender', value)}
          />
        </div>

        <Select
          className="max-w-86 mt-4"
          variant="outlined"
          fullWidth
          label={t('common.language')}
          options={allLangs}
          value={currentLanguage}
          placeholder={t('common.selectLanguage')}
          getOptionLabel={(option) => option.name}
          onChange={(option) => onChangeLanguage(option.value)}
        />

        <div className="flex items-end mt-10">
          <h2>{t('common.subscription')}</h2>
          {account.subscription?.status === SUBSCRIPTION_STATUS.PENDING ? (
            <span className="typo-button ml-auto">{t('common.pending')}</span>
          ) : account.subscription?.status === SUBSCRIPTION_STATUS.DEACTIVATED ? (
            <span className="typo-button text-danger ml-auto">{t('common.deactivated')}</span>
          ) : !account.subscription.expires_date ? (
            <span className="typo-button ml-auto">{t('common.activated')}</span>
          ) : (
            <span className={classNames('typo-button ml-auto', isExpired ? 'text-danger' : 'text-primary')}>
              {t('common.activeUntil#', { date: formatDatetime(account.subscription.expires_date, 'll') })}
            </span>
          )}
        </div>

        <div className="mt-6">
          <div>
            <a className="inline-block" href={privacyPolicyUrl} target="_blank" rel="noreferrer">
              <Button className="!p-0" variant="text">
                {t('common.privacyPolicy')}
              </Button>
            </a>
          </div>
          <div>
            <a className="inline-block" href={tocUrl} target="_blank" rel="noreferrer">
              <Button className="!p-0" variant="text">
                {t('common.termsAndConditions')}
              </Button>
            </a>
          </div>
          {CONFIG.THEME === 'imed' && (
            <div>
              <a className="inline-block" href="mailto:safronov84@gmx.de" target="_blank" rel="noreferrer">
                <Button className="!p-0" variant="text">
                  {t('common.support')}
                </Button>
              </a>
            </div>
          )}
        </div>

        <div className="mt-10">
          <Button className="" variant="outlined" theme="danger" onClick={() => setShowDeletePopup(true)}>
            {t('profile.deleteAccount')}
          </Button>
        </div>
      </div>

      {showDeletePopup && (
        <DeleteAccountPopup
          onDelete={onDeleteAccount}
          onCancel={() => setShowDeletePopup(false)}
        />
      )}
    </PatientLayout>
  );
};
