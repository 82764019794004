import React, {FC} from "react";
import {Icon} from "./Icon";
import {IIconProps} from "./types";

export const BarChartOutlineIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={16} baseHeight={16} {...props}>
    {(width, height, color) => (
      <svg width={width} height={height} viewBox="0 0 16 16">
        <path
          fill={color}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.33333 2.00008V12.6667H3.14667V2.00008L1.33333 2.00008ZM4.48 2.00008C4.48 1.2637 3.90692 0.666748 3.2 0.666748H1.28C0.573076 0.666748 0 1.2637 0 2.00008V12.6667C0 13.4031 0.573075 14.0001 1.28 14.0001H3.2C3.80375 14.0001 4.30987 13.5647 4.44475 12.9788C4.46779 12.8787 4.48 12.7742 4.48 12.6667V2.00008ZM5.81333 4.95132C5.77864 5.07236 5.76 5.20062 5.76 5.33341V12.6667C5.76 12.7742 5.77221 12.8787 5.79525 12.9788C5.93013 13.5647 6.43625 14.0001 7.04 14.0001H8.96C9.56375 14.0001 10.0699 13.5647 10.2047 12.9788C10.2278 12.8787 10.24 12.7742 10.24 12.6667V5.33341C10.24 4.59703 9.66692 4.00008 8.96 4.00008H7.04C6.46056 4.00008 5.97104 4.40115 5.81333 4.95132ZM11.5733 8.28465C11.5386 8.40569 11.52 8.53395 11.52 8.66675V12.6667C11.52 12.7742 11.5322 12.8787 11.5553 12.9788C11.6901 13.5647 12.1962 14.0001 12.8 14.0001H14.72C15.4269 14.0001 16 13.4031 16 12.6667V8.66675C16 7.93037 15.4269 7.33341 14.72 7.33341H12.8C12.2206 7.33341 11.731 7.73448 11.5733 8.28465ZM7.09333 5.33341V12.6667H8.90667V5.33341H7.09333ZM12.8533 8.66675V12.6667H14.6667V8.66675H12.8533Z"
        />
      </svg>
    )}
  </Icon>
);
