import React, {FC} from "react";
import {Icon} from "./Icon";
import {IIconProps} from "./types";

export const FlagIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={24} baseHeight={24} {...props}>
    {(width, height, color) => (
      <svg width={width} height={height} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M3.39255 12.8333V21.0833C3.39255 21.5896 3.01283 22 2.54442 22C2.07601 22 1.69629 21.5896 1.69629 21.0833V0.916668C1.69629 0.410407 2.07601 6.45662e-07 2.54442 6.45662e-07L18.2341 6.45661e-07C18.3085 -0.000134188 18.3815 0.0208513 18.446 0.0608536C18.5105 0.100856 18.5641 0.158469 18.6014 0.22792C18.6388 0.29737 18.6586 0.376216 18.6589 0.456556C18.6592 0.536896 18.6399 0.615906 18.6031 0.685667L15.5727 6.41667L18.6031 12.1477C18.6399 12.2174 18.6592 12.2964 18.6589 12.3768C18.6586 12.4571 18.6388 12.536 18.6014 12.6054C18.5641 12.6749 18.5105 12.7325 18.446 12.7725C18.3815 12.8125 18.3085 12.8335 18.2341 12.8333H3.39255ZM3.64699 1.83333C3.50647 1.83333 3.39255 1.95646 3.39255 2.10833V10.725C3.39255 10.8769 3.50647 11 3.64699 11H9.71756H16.0426L13.6079 6.91289C13.4279 6.61069 13.4279 6.22265 13.6079 5.92044L16.0426 1.83333H3.64699Z"
        />
      </svg>
    )}
  </Icon>
);
