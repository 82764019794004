import {PHOTO_TYPE, SCAN_TYPE, STATUS, SUBSCRIPTION_PLAN} from "../enums";
import {FileModel} from "./file.model";
import {ScanPointsModel} from "./keypoint.model";
import {ScanFeatureModel} from "./scan-feature.model";
import {ScanDeviation} from "./scan-deviation.model";

export class ScanModel {
  id: string;
  type: SCAN_TYPE;
  status: STATUS;
  product_id: SUBSCRIPTION_PLAN;
  spineLine?: [number, number, number][];
  spineAngles?: [number, number, number][];
  front: FileModel;
  side: FileModel;
  sit: FileModel;
  points: ScanPointsModel;
  features: ScanFeatureModel;
  healthScore: number;
  createdAt: string;
  updatedAt: string;
  scannedAt?: string;

  constructor(init?: Partial<ScanModel>) {
    const data = {
      id: null,
      ...init,
    };

    this.id = data.id;
    this.type = data.type;
    this.status = data.status;
    this.product_id = data.product_id;
    this.spineLine = data.spineLine;
    this.spineAngles = data.spineAngles;
    this.front = data.front;
    this.side = data.side;
    this.sit = data.sit;
    if (data.points) {
      this.points = new ScanPointsModel(data.points);
    }
    this.features = data.features;
    this.healthScore = data.healthScore || 0;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.scannedAt = data.scannedAt;
  }

  isPhotosValid() {
    return !!this.front?.src && !!this.side?.src && !!this.sit?.src;
  }

  getEmptyPhotos() {
    return [
      !this.front?.src && 'front',
      !this.side?.src && 'side',
      !this.sit?.src && 'sit',
    ].filter((item) => !!item);
  }

  getPointGroups(type: PHOTO_TYPE) {
    const points = this.points;
    if (!points) {
      return [];
    }

    switch (type) {
      case PHOTO_TYPE.FRONT:
        return [
          [points.front.LEFT_EAR, points.front.RIGHT_EAR],
          [points.front.LEFT_EYE, points.front.RIGHT_EYE, points.front.LEFT_EAR, points.front.RIGHT_EAR],
          [points.front.LEFT_SHOULDER, points.front.RIGHT_SHOULDER, points.front.LEFT_EAR, points.front.RIGHT_EAR],
          [points.front.LEFT_WRIST, points.front.RIGHT_WRIST, points.front.LEFT_SHOULDER, points.front.RIGHT_SHOULDER],
          [points.front.LEFT_KNEE, points.front.RIGHT_KNEE, points.front.LEFT_ANKLE, points.front.RIGHT_ANKLE],
          [points.front.LEFT_ANKLE, points.front.RIGHT_ANKLE, points.front.LEFT_FOOT, points.front.RIGHT_FOOT],
          [points.front.LEFT_FOOT, points.front.RIGHT_FOOT],
          [points.front.LEFT_HIP, points.front.RIGHT_HIP, points.front.LEFT_WRIST, points.front.RIGHT_WRIST],
          [points.front.LEFT_HIP, points.front.RIGHT_HIP, points.front.LEFT_SHOULDER, points.front.RIGHT_SHOULDER],
          [points.front.LEFT_HIP, points.front.RIGHT_HIP, points.front.LEFT_KNEE, points.front.RIGHT_KNEE],
        ];

      case PHOTO_TYPE.SIDE:
        return [
          [points.feature.la],
          [points.feature.ka],
          [points.feature.neck],
          [points.side.LEFT_HEEL],
          [points.side.LEFT_EYE, points.feature.neck],
          [points.side.LEFT_KNEE, points.side.LEFT_HEEL],
          [points.side.LEFT_SHOULDER, points.feature.neck],
          [points.side.LEFT_SHOULDER, points.side.LEFT_KNEE],
        ];

      case PHOTO_TYPE.SIT:
        return [
          [points.sit.LEFT_EAR, points.sit.RIGHT_EAR, points.sit.LEFT_SHOULDER, points.sit.RIGHT_SHOULDER],
          [points.sit.LEFT_SHOULDER, points.sit.RIGHT_SHOULDER, points.sit.LEFT_HIP, points.sit.RIGHT_HIP],
          [points.sit.LEFT_HIP, points.sit.RIGHT_HIP],
        ];
    }
  }

  private calculateDeviationScore(value: number, min: number, max: number) {
    value = Math.min(max, Math.max(min, Math.abs(value || 0)));
    return Math.round((value - min) / (max - min) * 100);
  }

  getDeviation(): ScanDeviation {
    const {front, side, sit} = this.features;
    return {
      front: {
        headPosition: this.calculateDeviationScore((front.ears_angle + front.eyes_angle) / 2, 0, 1.5),
        shoulderPosition: this.calculateDeviationScore(front.shoulders_angle, 0, 1.375),
        legAxis: this.calculateDeviationScore(front.LEFT_KNEE_ANGLE - front.RIGHT_KNEE_ANGLE, 0, 1.875),
      },
      side: {
        cervicalSpine: this.calculateDeviationScore(side.NECK, 0, 100),
        thoracicSpine: this.calculateDeviationScore(side.ICT_ITL, 0, 100),
        lumbarSpine: this.calculateDeviationScore(side.ITL_ILS, 0, 100),
      },
      sit: {
        headPosition: this.calculateDeviationScore(sit.ears_angle, 0, 1.5),
        shoulderPosition: this.calculateDeviationScore(sit.shoulders_angle, 0, 1.375),
        hipPosition: this.calculateDeviationScore(sit.hips_angle, 0, 1.5),
      },
    };
  }
}
