export class PartnerModel {
  id: string;
  companyName: string;
  contactName: string;
  email: string;
  themeName: string;
  labelCode: string;

  constructor(init?: Partial<PartnerModel>) {
    const data = {
      id: null,
      ...init,
    };

    this.id = data.id;
    this.companyName = data.companyName;
    this.contactName = data.contactName;
    this.email = data.email;
    this.themeName = data.themeName;
    this.labelCode = data.labelCode;
  }
}
