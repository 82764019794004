import i18next, {InitOptions} from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {CONFIG} from "../constants";
import enTranslation from "./translations/en.json";
import deTranslation from "./translations/de.json";
import frTranslation from "./translations/fr.json";
import polTranslation from "./translations/pl.json";
import esTranslation from "./translations/es.json";
import itTranslation from "./translations/it.json";
import grTranslation from "./translations/el.json";
import roTranslation from "./translations/ro.json";
import {LANGUAGE} from "../resources/enums";

export const allLangs = [
  { name: 'English', value: LANGUAGE.EN },
  { name: 'Deutsch', value: LANGUAGE.DE },
  { name: 'Français', value: LANGUAGE.FR },
  { name: 'Polski ', value: LANGUAGE.PL },
  { name: 'Español', value: LANGUAGE.ES },
  { name: 'Italiano', value: LANGUAGE.IT },
  { name: 'Ελληνικά', value: LANGUAGE.EL },
  { name: 'Română', value: LANGUAGE.RO },
];

export const resources = {
  en: {
    translation: enTranslation,
  },
  de: {
    translation: deTranslation,
  },
  fr: {
    translation: frTranslation,
  },
  pl: {
    translation: polTranslation,
  },
  es: {
    translation: esTranslation,
  },
  it: {
    translation: itTranslation,
  },
  el: {
    translation: grTranslation,
  },
  ro: {
    translation: roTranslation,
  },
};

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // Standard language used
    fallbackLng: 'en',
    debug: CONFIG.DEBUG,
    resources,
    // Detects and caches a cookie from the language provided
    detection: {
      order: ['localStorage'],
      cache: ['localStorage'],
    },
    interpolation: {
      escapeValue: false,
    },
  } as InitOptions);

export default i18next;
