import React, {FC} from "react";
import {Icon} from "./Icon";
import {IIconProps} from "./types";

export const VolumeOffIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={24} baseHeight={24} {...props}>
    {(width, height, color) => (
      <svg width={width} height={height} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M13.82 0.0167736C10.7793 0.5969 8.07468 2.34213 6.266 4.8912H5C3.67457 4.89335 2.40403 5.42899 1.4668 6.38075C0.529579 7.33251 0.00211562 8.62275 0 9.96874V14.0308C0.00211562 15.3767 0.529579 16.667 1.4668 17.6187C2.40403 18.5705 3.67457 19.1061 5 19.1083H6.266C8.07504 21.657 10.7795 23.4021 13.82 23.9827C13.8796 23.9945 13.9403 24.0003 14.001 24C14.2662 24 14.5206 23.893 14.7081 23.7025C14.8956 23.5121 15.001 23.2538 15.001 22.9845V1.01806C15.0014 0.868919 14.9694 0.721524 14.9073 0.586353C14.8453 0.451182 14.7546 0.331553 14.6419 0.235968C14.5292 0.140384 14.3971 0.0711909 14.255 0.0333069C14.113 -0.00457703 13.9644 -0.0102218 13.82 0.0167736ZM13 21.6826C10.7944 20.9686 8.89788 19.5056 7.629 17.5393C7.53834 17.3977 7.41432 17.2813 7.26816 17.2006C7.12201 17.1199 6.95835 17.0775 6.792 17.0773H5C4.20435 17.0773 3.44129 16.7563 2.87868 16.185C2.31607 15.6136 2 14.8387 2 14.0308V9.96874C2 9.16075 2.31607 8.38585 2.87868 7.81452C3.44129 7.24319 4.20435 6.92222 5 6.92222H6.8C6.96617 6.92219 7.12971 6.88012 7.27585 6.7998C7.42198 6.71949 7.54611 6.60345 7.637 6.46219C8.90329 4.49641 10.7969 3.03277 13 2.3169V21.6826Z"
        />
        <path
          fill={color}
          d="M22.2929 14.486C22.6834 14.8765 23.3166 14.8765 23.7071 14.486C24.0976 14.0955 24.0976 13.4623 23.7071 13.0718L22.2929 11.6576L23.7071 10.2434C24.0976 9.85283 24.0976 9.21966 23.7071 8.82914C23.3166 8.43861 22.6834 8.43861 22.2929 8.82914L20.8787 10.2434L19.4645 8.82914C19.0739 8.43861 18.4408 8.43861 18.0503 8.82914C17.6597 9.21966 17.6597 9.85283 18.0503 10.2434L19.4645 11.6576L18.0503 13.0718C17.6597 13.4623 17.6597 14.0955 18.0503 14.486C18.4408 14.8765 19.0739 14.8765 19.4645 14.486L20.8787 13.0718L22.2929 14.486Z"
        />
      </svg>
    )}
  </Icon>
);
