import React, {FC} from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import classNames from "classnames";
import {RunIcon} from "../../icons";

export interface IExerciseTutorialCardProps {
  className?: string;
}

export const ExerciseTutorialCard: FC<IExerciseTutorialCardProps> = ({
  className,
}) => {
  const { t } = useTranslation();

  return (
    <Link
      className={classNames('card4 block px-4 py-3', className)}
      to="/exercises/tutorial"
    >
      <div className="flex max-lg:items-center">
        <RunIcon className="lg:mt-1 text-typo1 !text-card4-title" size={24} />
        <h6 className="ml-3 mr-4 !text-card4-title leading-normal">{t('patient.howWillMyProgramLookLike')}</h6>
        <i className="fa fa-angle-right p-1 ml-auto !text-card4-icon" />
      </div>
      <p className="mt-2">
        {t('patient.watch30SecondVideo')}.
      </p>
    </Link>
  );
};
