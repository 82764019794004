import {createAsyncThunk} from "@reduxjs/toolkit";
import {
  PaginatedResultDto,
  PaginateRequestDto,
  SearchScansRequestDto
} from "../../resources/dtos";
import {EXERCISE_TYPE_VALUES, LANGUAGE, LOADING_STATUS} from "../../resources/enums";
import {ChallengeModel, ExerciseModel, IssueModel, PhotoTipModel, ScanModel} from "../../resources/models";
import {RootState} from "../store";
import {
  setChallenge,
  setChallengeLoadingState,
  setDailyExercises,
  setExercisesLoadingState,
  setFirstAidKits,
  setFirstAidKitsLoadingState,
  setIssues,
  setIssuesLoadingState,
  setPhotoTips,
  setPhotoTipsLoadingState,
  setScan, setTrainStatus, setTrainStatusLoadingState,
} from "../reducers/patient.reducer";
import {ChallengesService, ExercisesService, ScansService, TipsService, ToastService} from "../../services";
import {fetchAccountProfile} from "./auth.apis";
import {ITrainStatus} from "../../resources/interfaces";

export const fetchScans = createAsyncThunk<PaginatedResultDto<ScanModel>, {
  query?: SearchScansRequestDto;
  showSpinner?: boolean;
  showError?: boolean;
}>('patient/fetchScans', async (arg, api) => {
  return ScansService.search(arg.query, arg.showSpinner)
    .then((data) => {
      if (data.total) {
        api.dispatch(setScan(data.result[data.total - 1]));
      }
      return data;
    })
    .catch((err) => {
      if (arg.showError ?? true) {
        ToastService.showHttpError(err, 'toast.loadingScansFailed');
      }
      throw err;
    });
});

export const fetchDailyExercises = createAsyncThunk<ExerciseModel[], {
  query?: { lang: LANGUAGE };
  force?: boolean;
  showSpinner?: boolean;
  showError?: boolean;
}>('patient/fetchDailyExercises', async (arg, api) => {
  api.dispatch(setExercisesLoadingState(LOADING_STATUS.PENDING));
  const root = api.getState() as RootState;
  const { scan } = root.patient;
  return ScansService.getDailyExercises(scan.id, arg.query, arg.showSpinner)
    .then((data) => {
      api.dispatch(setDailyExercises(data));
      api.dispatch(setExercisesLoadingState(LOADING_STATUS.SUCCESS));
      return data;
    })
    .catch((err) => {
      if (arg.showError ?? true) {
        ToastService.showHttpError(err, 'toast.loadingExercisesFailed');
      }
      api.dispatch(setExercisesLoadingState(LOADING_STATUS.FAILED));
      throw err;
    });
}, {
  condition(arg, api) {
    const root = api.getState() as RootState;
    const { scan, exercisesLoadingStatus } = root.patient;
    return Boolean(scan && (arg.force || exercisesLoadingStatus === LOADING_STATUS.NONE));
  }
});

export const fetchTrainStatus = createAsyncThunk<ITrainStatus, {
  force?: boolean;
  showSpinner?: boolean;
  showError?: boolean;
}>('patient/fetchTrainStatus', async (arg, api) => {
  api.dispatch(setTrainStatusLoadingState(LOADING_STATUS.PENDING));
  const root = api.getState() as RootState;
  const { scan } = root.patient;
  return ScansService.getTrainStatus(scan.id, arg.showSpinner)
    .then((data) => {
      api.dispatch(setTrainStatus(data));
      api.dispatch(setTrainStatusLoadingState(LOADING_STATUS.SUCCESS));
      return data;
    })
    .catch((err) => {
      if (arg.showError ?? true) {
        ToastService.showHttpError(err, 'toast.loadingTrainStatusFailed');
      }
      api.dispatch(setTrainStatusLoadingState(LOADING_STATUS.FAILED));
      throw err;
    });
}, {
  condition(arg, api) {
    const root = api.getState() as RootState;
    const { scan, trainStatusLoadingStatus } = root.patient;
    return Boolean(scan && (arg.force || trainStatusLoadingStatus === LOADING_STATUS.NONE));
  }
});

export const fetchIssues = createAsyncThunk<PaginatedResultDto<IssueModel>, {
  query?: PaginateRequestDto;
  force?: boolean;
  showSpinner?: boolean;
  showError?: boolean;
}>('patient/fetchIssues', async (arg, api) => {
  api.dispatch(setIssuesLoadingState(LOADING_STATUS.PENDING));
  const root = api.getState() as RootState;
  const { scan } = root.patient;
  return ScansService.searchFeatures(scan.id, arg.query, arg.showSpinner)
    .then((data) => {
      api.dispatch(setIssues(data.result.splice(0, 5)));
      api.dispatch(setIssuesLoadingState(LOADING_STATUS.SUCCESS));
      return data;
    })
    .catch((err) => {
      if (arg.showError ?? true) {
        ToastService.showHttpError(err, 'toast.loadingIssuesFailed');
      }
      api.dispatch(setIssuesLoadingState(LOADING_STATUS.FAILED));
      throw err;
    });
}, {
  condition(arg, api) {
    const root = api.getState() as RootState;
    const { scan, issuesLoadingStatus } = root.patient;
    return Boolean(scan && (arg.force || issuesLoadingStatus === LOADING_STATUS.NONE));
  }
});

export const fetchPhotoTips = createAsyncThunk<PaginatedResultDto<PhotoTipModel>, {
  query?: PaginateRequestDto;
  force?: boolean;
  showSpinner?: boolean;
  showError?: boolean;
}>('patient/fetchPhotoTips', async (arg, api) => {
  api.dispatch(setPhotoTipsLoadingState(LOADING_STATUS.PENDING));
  return TipsService.search(arg.query, arg.showSpinner)
    .then((data) => {
      api.dispatch(setPhotoTips(data.result));
      api.dispatch(setPhotoTipsLoadingState(LOADING_STATUS.SUCCESS));
      return data;
    })
    .catch((err) => {
      if (arg.showError ?? true) {
        ToastService.showHttpError(err, 'toast.loadingPhotoTipsFailed');
      }
      api.dispatch(setPhotoTipsLoadingState(LOADING_STATUS.FAILED));
      throw err;
    });
}, {
  condition(arg, api) {
    const root = api.getState() as RootState;
    const { photoTipsLoadingStatus } = root.patient;
    return Boolean(arg.force || photoTipsLoadingStatus === LOADING_STATUS.NONE);
  }
});

export const fetchFirstAidKits = createAsyncThunk<ExerciseModel[], {
  query?: { lang: LANGUAGE };
  force?: boolean;
  showSpinner?: boolean;
  showError?: boolean;
}>('patient/fetchFirstAidKits', async (arg, api) => {
  api.dispatch(setFirstAidKitsLoadingState(LOADING_STATUS.PENDING));
  return Promise.all(
    EXERCISE_TYPE_VALUES.map((type) => ExercisesService
      .getFirstAidKit({ type, lang: arg.query?.lang }, arg.showSpinner)
      .catch(() => undefined)
    )
  ).then((res) => {
    const data = res.filter((item) => !!item);
    api.dispatch(setFirstAidKits(data));
    api.dispatch(setFirstAidKitsLoadingState(LOADING_STATUS.SUCCESS));
    return data;
  }).catch((err) => {
    if (arg.showError ?? true) {
      ToastService.showHttpError(err, 'toast.loadingFirstAidKitsFailed');
    }
    api.dispatch(setFirstAidKitsLoadingState(LOADING_STATUS.FAILED));
    throw err;
  });
}, {
  condition(arg, api) {
    const root = api.getState() as RootState;
    const { firstAidKitsLoadingStatus } = root.patient;
    return Boolean(arg.force || firstAidKitsLoadingStatus === LOADING_STATUS.NONE);
  }
});

export const createChallenge = createAsyncThunk<ChallengeModel, {
  showSpinner?: boolean;
  showError?: boolean;
}>('patient/createChallenge', async (arg, api) => {
  api.dispatch(setChallengeLoadingState(LOADING_STATUS.PENDING));
  return ChallengesService.create(arg.showSpinner)
    .then((data) => {
      api.dispatch(setChallenge(data));
      api.dispatch(setChallengeLoadingState(LOADING_STATUS.SUCCESS));
      api.dispatch(fetchAccountProfile({ force: true, showSpinner: false, showError: false }));
      return data;
    })
    .catch((err) => {
      if (arg.showError ?? true) {
        ToastService.showHttpError(err, 'toast.creatingChallengeFailed');
      }
      api.dispatch(setChallengeLoadingState(LOADING_STATUS.FAILED));
      throw err;
    });
}, {
  condition(arg, api) {
    const root = api.getState() as RootState;
    const { scan } = root.patient;
    return Boolean(scan);
  }
});
