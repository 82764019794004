import React, {FC} from "react";
import classNames from "classnames";
import {Trans, useTranslation} from "react-i18next";
import {Button, Popup} from "../../components";

export interface IDeleteAccountPopupProps {
  className?: string;
  onDelete(): void;
  onCancel(): void;
}

export const DeleteAccountPopup: FC<IDeleteAccountPopupProps> = ({
  className,
  onDelete,
  onCancel,
}) => {
  const { t } = useTranslation();

  return (
    <Popup
      contentClass={classNames('text-typo1', className)}
      width="25rem"
      disableBackdrop
    >
      <h1>{t('profile.deleteAccount')}</h1>
      <p className="font-bold mt-1">
        <Trans i18nKey="profile.deleteAccountConfirm" />
      </p>
      <p className="mt-1">
        <Trans
          i18nKey="profile.deleteAccountHelperText"
          components={[<a className="text-primary" href="mailto:support@iplena.us" target="_blank" rel="noreferrer" />]}
        />
      </p>
      <Button className="mt-4" fullWidth onClick={onDelete}>
        {t('profile.deleteAccountYesButtonText')}
      </Button>
      <Button className="mt-4" variant="outlined" fullWidth onClick={onCancel}>
        {t('common.cancel')}
      </Button>
    </Popup>
  );
};
