import axios, {AxiosError, AxiosRequestConfig, Method} from "axios";
import {CONFIG} from "../constants";
import {store} from "../redux/store";
import {setToken} from "../redux/reducers/auth.reducer";
import {finishLoading, startLoading} from "../redux/reducers/spinner.reducer";

const http = axios.create({
  baseURL: CONFIG.API_SERVER,
});

http.interceptors.request.use((req) => {
  const token = localStorage.getItem(CONFIG.TOKEN_KEY);
  if (token) {
    req.headers.set('Authorization', `Bearer ${token}`);
  }
  return req;
});

http.interceptors.response.use((res) => {
  return res.data;
}, (err: AxiosError) => {
  if (err.response?.status === 401 && !err.config.url.startsWith('/auth')) {
    store.dispatch(setToken(null));
    window.location.href = '/login';
  }
  throw err;
});

export class HttpService {
  static get(url: string, query: any = {}, hasSpinner = true) {
    return HttpService.request('GET', url, { params: query }, hasSpinner);
  }

  static post(url: string, data: any = {}, hasSpinner = true) {
    return HttpService.request('POST', url, { data }, hasSpinner);
  }

  static put(url: string, data: any = {}, hasSpinner = true) {
    return HttpService.request('PUT', url, { data }, hasSpinner);
  }

  static patch(url: string, data: any = {}, hasSpinner = true) {
    return HttpService.request('PATCH', url, { data }, hasSpinner);
  }

  static delete(url: string, data: any = {}, hasSpinner = true) {
    return HttpService.request('DELETE', url, { data }, hasSpinner);
  }

  static request(method: Method, url: string, data?: AxiosRequestConfig, hasSpinner = true): Promise<any> {
    if (hasSpinner) {
      store.dispatch(startLoading());
    }

    return http.request({
      method,
      url,
      ...data,
    }).finally(() => {
      if (hasSpinner) {
        store.dispatch(finishLoading());
      }
    });
  }
}
