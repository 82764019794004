export class ScanIssueModel {
  probability: number;

  constructor(init?: Partial<ScanIssueModel>) {
    const data = {
      id: null,
      ...init,
    };

    this.probability = data.probability;
  }
}
