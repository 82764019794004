import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {CONFIG} from "../../../../constants";
import {PatientLayout} from "../../../../layouts";
import {
  PageBackButton,
  FirstAidKits,
  HowToWinChallenge,
} from "../../../../parts";

export const Tutorial = () => {
  const { t } = useTranslation();

  const videoUrl = useMemo(() => {
    switch (CONFIG.THEME) {
      case 'imed':
        return 'https://player.vimeo.com/video/884287251?autoplay=1&controls=1&badge=0&byline=0&portrait=0&title=0&loop=1';

      default:
        return 'https://player.vimeo.com/video/855274526?autoplay=1&controls=1&badge=0&byline=0&portrait=0&title=0&loop=1';
    }
  }, []);

  const sidebar = (
    <>
      <FirstAidKits />
    </>
  );

  return (
    <PatientLayout sidebar={sidebar}>
      <PageBackButton />

      <h1 className="mt-8">{t('patient.howWillMyProgramLookLike')}</h1>

      <div className="flex-center mt-6 max-w-160">
        <iframe
          src={videoUrl}
          width="640"
          height="360"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        />
      </div>

      <div className="mt-8">
        <h3>{t('patient.howToWinChallenge')}</h3>
        <HowToWinChallenge className="mt-4" />
      </div>
    </PatientLayout>
  );
};
