import React from "react";
import {useTranslation} from "react-i18next";
import {PatientLayout} from "../../../../layouts";
import {PageBackButton} from "../../../../parts";
import {Image} from "../../../../components";
import {useSelector} from "../../../../redux/store";

export const PhotoTips = () => {
  const { t } = useTranslation();
  const { photoTips } = useSelector((root) => root.patient);

  return (
    <PatientLayout>
      <PageBackButton />
      <h1 className="mt-8">{t('patient.photoTips')}</h1>
      <div className="tips-section grid sm:grid-cols-2 lg:grid-cols-3 gap-4 xl:gap-15 mt-8">
        {photoTips.map((item) => (
          <Image
            key={item.id}
            wrapperClass="max-lg:max-w-86 bg-[#041D24] border border-[#03617B] rounded-10p overflow-hidden m-auto"
            aspectRatio={2/3}
            spinner
            src={item.getCover()?.src}
          />
        ))}
      </div>
    </PatientLayout>
  );
};
