import React, {FC} from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import classNames from "classnames";
import {Image, ProgressBar} from "../../components";
import {IssueModel} from "../../resources/models";

export interface IIssueCardProps {
  className?: string;
  issue: IssueModel;
}

export const IssueCard: FC<IIssueCardProps> = ({
  className,
  issue,
}) => {
  const { t } = useTranslation();
  const progress = issue.getProbability();

  return (
    <Link
      className={classNames('card1 h-26 flex items-center', className)}
      to={`/scanner/issues/${issue.id}`}
    >
      <Image
        wrapperClass="!w-22 h-full shrink-0 bg-black"
        src={issue.getCover()?.src}
        spinner
        spinnerSize="sm"
      />
      <div className="flex-1 px-4 py-3">
        <h6 className="line-clamp-2">{issue.getLocalizedContent()?.title || ''}</h6>
        <div className="flex items-center justify-between text-primary text-11p mt-1">
          <span>{t('patient.healthIssues.issueProbability')}</span>
          <span>{Math.floor(progress)}%</span>
        </div>
        <ProgressBar className="mt-1" theme="primary" percent={progress} size="sm" />
      </div>
      <i className="fa fa-angle-right pl-2 pr-3" />
    </Link>
  );
};
