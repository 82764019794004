import {LANGUAGE} from "../enums";
import {FileModel} from "./file.model";

export class ContentModel {
  id: string;
  language: LANGUAGE;
  title: string;
  description?: string;
  video?: string;
  featureSymptoms: string[];
  subtitles: Partial<FileModel>;
  notificationId: string;
  createdAt: string;
  updatedAt: string;

  constructor(init?: Partial<ContentModel>) {
    const data = {
      id: null,
      ...init,
    };

    this.id = data.id;
    this.language = data.language;
    this.title = data.title;
    this.description = data.description;
    this.video = data.video?.replace('https://vimeo.com', 'https://player.vimeo.com/video');
    this.featureSymptoms = data.featureSymptoms;
    if (data.subtitles) {
      this.subtitles = new FileModel(data.subtitles);
    }
    this.notificationId = data.notificationId;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }
}
