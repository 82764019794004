import React, {ReactNode, useCallback, useState} from "react";
import classNames from "classnames";
import {Button} from "../Button";
import {ClickAwayListener} from "../ClickAwayListener";

export interface ISelectProps<T> {
  className?: string;
  theme?: 'primary' | 'secondary';
  variant?: 'contained' | 'outlined';
  fullWidth?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  value?: T;
  options: T[];
  label?: ReactNode;
  labelClass?: string;
  placeholder?: ReactNode;
  dropdownClass?: string;
  disabled?: boolean;
  getOptionLabel?: (option: T) => ReactNode;
  onChange?: (option: T) => void;
}

export function Select<T>({
  className,
  theme = 'primary',
  variant = 'contained',
  fullWidth,
  size = 'md',
  value,
  options,
  getOptionLabel,
  label,
  labelClass,
  placeholder,
  dropdownClass,
  disabled,
  onChange,
}: ISelectProps<T>) {
  const [opened, setOpened] = useState(false);

  const wrapperClass = classNames(
    'relative',
    fullWidth && 'w-full',
    size === 'md' && 'text-md',
    className,
  );

  const dropdownMenuClass = classNames(
    'absolute left-0 w-full bg-menu-bg text-menu-text shadow overflow-hidden',
    size === 'md' && 'rounded-lg',
    dropdownClass,
  );

  const getMenuItemClass = (option: T) => {
    return classNames(
      'transition-all cursor-pointer',
      'hover:bg-menu-bg-hover',
      size === 'md' && 'px-4 py-2',
      option === value && 'text-menu-active',
    );
  };

  const getLabel = (option: T) => {
    if (getOptionLabel) {
      return getOptionLabel(option);
    }
    return option?.toString();
  };

  const onSelectOption = (option: T) => {
    if (disabled) {
      return;
    }
    onChange && onChange(option);
    setOpened(false);
  };

  const onCloseDropdown = useCallback(() => {
    setOpened(false);
  }, []);

  return (
    <ClickAwayListener className={wrapperClass} onClick={onCloseDropdown}>
      {label && (
        <div className={classNames('typo-sm px-1', labelClass)}>{label}</div>
      )}
      <Button
        theme={theme}
        variant={variant}
        size={size}
        fullWidth
        onClick={() => setOpened(!opened)}
      >
        {value ? getLabel(value) : <span className="text-typo2">{placeholder}</span>}
        <i className={classNames('fa fa-angle-down transition-all ml-auto', opened && 'rotate-180')} />
      </Button>
      {opened && (
        <div className={dropdownMenuClass}>
          {options.map((option, i) => (
            <div key={i} className={getMenuItemClass(option)} onClick={() => onSelectOption(option)}>
              {getLabel(option)}
            </div>
          ))}
        </div>
      )}
    </ClickAwayListener>
  );
}
