import React, {useEffect, useRef, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Autoplay, Navigation} from "swiper";
import {Swiper, SwiperRef, SwiperSlide} from "swiper/react";
import {Button, Image} from "../../../../components";
import {PatientLayout} from "../../../../layouts";
import {
  ExerciseLevel,
  ExportScanReportPdfButton,
  HealthIssues,
  ScanPhotosView,
  SharePhotosButton,
} from "../../../../parts";
import {useDispatch, useSelector} from "../../../../redux/store";
import {STATUS} from "../../../../resources/enums";

export const ScanResult = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { scan, issues, trainStatus } = useSelector((root) => root.patient);
  const remainTrainCount = trainStatus?.remainTrainCount || 0;

  const [activeId, setActiveId] = useState(0);
  const swiperRef = useRef<SwiperRef>(null);

  const activeIssue = issues[activeId];

  useEffect(() => {
    if (scan?.status !== STATUS.COMPLETED) {
      navigate('/scanner');
    }
  }, [dispatch, navigate, scan]);

  const onShowPrev = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.navigation.prevEl.click();
    }
  };

  const onShowNext = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.navigation.nextEl.click();
    }
  };

  const sidebar = (
    <>
      {scan && (
        <div>
          <div className="flex-center">
            <h2>{t('patient.yourAnalyzedPhotos')}</h2>
            <SharePhotosButton className="!h-auto !text-primary !p-0 ml-auto" scan={scan} />
          </div>

          <ScanPhotosView className="mt-4" scan={scan} link />

          <h6 className="mt-5">{t('patient.detectedIssues')}</h6>
          <HealthIssues className="mt-4" />

          <ExportScanReportPdfButton className="mt-4" fullWidth />
        </div>
      )}
    </>
  );

  return (
    <PatientLayout sidebar={sidebar}>
      <ExerciseLevel />

      {activeIssue && (
        <h2 className="mt-12">
          {activeId + 1} / {issues.length} {activeIssue.getLocalizedContent()?.title}
        </h2>
      )}

      {issues.length > 0 && (
        <div className="grid xl:grid-cols-scan-result gap-6 mt-6">
          <div className="w-full max-xl:max-w-80 m-auto">
            <Swiper
              ref={swiperRef}
              className="hide-navigation"
              modules={[Autoplay, Navigation]}
              slidesPerView={1}
              centeredSlides
              navigation
              autoplay={{ delay: 10000 }}
              rewind
              onSlideChange={(e) => setActiveId(e.activeIndex)}
            >
              {issues.map((item, i) => (
                <SwiperSlide key={i}>
                  <Link to={`/scanner/issues/${item.id}`}>
                    <Image aspectRatio={1} src={item.getCover()?.src} spinner />
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="flex items-center mt-2">
              <Button className="mx-auto" variant="text" onClick={onShowPrev}>
                <i className="fa fa-arrow-left mr-2" /> {t('common.previous')}
              </Button>
              <Button className="mx-auto" variant="text" onClick={onShowNext}>
                {t('common.next')} <i className="fa fa-arrow-right ml-2" />
              </Button>
            </div>
          </div>

          {activeIssue && (
            <div>
              <div className="progress-wrapper">
                <div className="flex items-center justify-between typo-sm text-typo1">
                  <span>{t('common.low')}</span>
                  <span>{t('common.high')}</span>
                </div>
                <input
                  className="gradient-range-slider xl:mt-1"
                  type="range"
                  min={0}
                  max={100}
                  value={activeIssue.getProbability()}
                  disabled
                />
              </div>
              <p className="mt-6">
                {activeIssue.getLocalizedContent()?.description}
              </p>
              <Button className="mt-6" fullWidth disabled={!remainTrainCount} link="/exercises">
                {t('patient.startTraining')}
              </Button>
            </div>
          )}
        </div>
      )}
    </PatientLayout>
  );
};
