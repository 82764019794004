import React, {FC} from "react";
import {Icon} from "./Icon";
import {IIconProps} from "./types";

export const UserExerciseIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={24} baseHeight={24} {...props}>
    {(width, height, color) => (
      <svg width={width} height={height} viewBox="0 0 24 24">
        <path
          fill={color}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.4788 4.97838L15.1485 1.90655L15.1677 1.86293C15.4588 1.20076 15.7215 0.603302 15.0054 0.134878C14.3147 -0.316967 13.6937 0.470001 13.4038 0.972252L11.5438 4.00214C11.2981 4.42777 11.4486 4.95052 11.6094 5.50897L11.6329 5.59085L12.4984 8.86181L10.0472 13.0459L6.27571 18.9526L4.21063 22.0285C3.85606 22.5275 3.95962 23.2348 4.4424 23.6112C4.92886 23.9904 5.61526 23.8899 5.97253 23.387L11.0332 15.7873L12.6165 16.5323L15.5972 19.1115V22.8059C15.5809 23.4161 16.0625 23.948 16.6748 23.9965C17.2918 24.0452 17.8071 23.5848 17.8235 22.97L17.9766 18.7228C17.9905 18.2018 17.571 17.8486 17.1229 17.4713L17.0573 17.4159L15.3706 15.9227C16.5185 15.5583 18.9339 14.7287 19.4782 14.4927C20.0225 14.2567 20.0799 13.5805 19.926 13.3681C19.254 12.4408 17.8673 10.5275 17.6954 10.2921C17.5236 10.0567 17.2317 9.68148 16.8867 9.49075L14.2936 7.93907L13.4788 4.97838ZM16.4475 11.7804L15.1532 13.9999L17.4689 13.2046L16.4475 11.7804ZM15.2732 7.84123C15.6881 8.26625 16.2507 8.50502 16.8374 8.50502C17.4241 8.50502 17.9868 8.26625 18.4016 7.84123C18.8165 7.41621 19.0496 6.83976 19.0496 6.23869C19.0496 5.63762 18.8165 5.06117 18.4016 4.63616C17.9868 4.21114 17.4241 3.97236 16.8374 3.97236C16.2507 3.97236 15.6881 4.21114 15.2732 4.63616C14.8584 5.06117 14.6253 5.63762 14.6253 6.23869C14.6253 6.83976 14.8584 7.41621 15.2732 7.84123Z"
        />
      </svg>
    )}
  </Icon>
);
