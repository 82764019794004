import React, {FC, useState} from "react";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";
import classNames from "classnames";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {Button, Popup} from "../../components";
import {AuthService, ToastService} from "../../services";

export interface IForgotPasswordPopupProps {
  className?: string;
  onClose(): void;
}

export const ForgotPasswordPopup: FC<IForgotPasswordPopupProps> = ({
  className,
  onClose,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const formSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('validationErrors.invalidEmail'))
      .required(t('validationErrors.requiredField')),
  });

  const initialFormValues = {
    email: '',
  };

  const onSubmit = (form) => {
    setLoading(true);
    AuthService.forgotPassword(form.email, false)
      .then(() => {
        ToastService.success('toast.forgotPasswordRequestSent');
        onClose();
      })
      .catch((err) => ToastService.showHttpError(err, 'toast.forgotPasswordFailed'))
      .finally(() => setLoading(false));
  };

  return (
    <Popup
      contentClass={classNames('text-typo1', className)}
      width="24rem"
      onClose={onClose}
    >
      <Formik
        initialValues={initialFormValues}
        validationSchema={formSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          const { errors, touched } = formik;
          return (
            <Form className="formik-form">
              <h1>{t('auth.forgotPassword')}</h1>
              <p className="mt-8">{t('auth.forgotPasswordHelperText')}.</p>

              <div className={classNames(
                'form-field full-width rounded-sm size-md mt-2',
                errors.email && touched.email && 'is-invalid',
              )}>
                <Field
                  name="email"
                  placeholder={t('common.email')}
                />
                <ErrorMessage name="email" component="div" className="invalid-feedback mt-1 ml-2" />
              </div>

              <Button className="mt-8" type="submit" fullWidth loading={loading}>
                {t('common.submit')}
              </Button>
              <Button className="mt-4" type="button" variant="outlined" fullWidth onClick={onClose}>
                {t('common.cancel')}
              </Button>
            </Form>
          );
        }}
      </Formik>
    </Popup>
  );
};
