import React, {FC} from "react";
import classNames from "classnames";
import {Trans, useTranslation} from "react-i18next";
import {buildStyles, CircularProgressbar} from "react-circular-progressbar";
import {useSelector} from "../../redux/store";

export interface IExerciseLevelProps {
  className?: string;
}

export const ExerciseLevel: FC<IExerciseLevelProps> = ({
  className,
}) => {
  const { t } = useTranslation();

  const { challenge, trainStatus } = useSelector((state) => state.patient);
  const isNewLevel = trainStatus?.isNewLevel;
  const todayLevel = trainStatus?.todayLevel || 1;
  const trainLevel = trainStatus?.trainLevel || 3;
  const progress = isNewLevel ? 100 : Math.round((trainLevel % 3) / 3 * 100);

  return (
    <div className={classNames('relative flex items-center', className)}>
      <div className="progress-section flex-center flex-col mr-8">
        <CircularProgressbar
          className="w-22"
          value={progress}
          text={challenge ? `${progress}%` : '?'}
          strokeWidth={6}
          styles={buildStyles({
            rotation: 0.5,
            strokeLinecap: 'round',
            textSize: '1.25rem',
          })}
        />
        {challenge && (
          <div className="typo-xs text-center mt-2">{t('patient.trainingProcess')}</div>
        )}
      </div>

      {challenge ? (
        <div>
          <h1>{t('common.level#', { level: todayLevel })}</h1>
          {isNewLevel ? (
            <p className="max-w-86 typo-xs mt-2">
              <b>{t('common.congratulations')}</b>&nbsp;
              <Trans i18nKey="patient.youFinishedLevel#" values={{ level: todayLevel }}>
                You are now at <span className="text-primary font-semibold">Level</span>
              </Trans>!
              <br />
              {t('patient.tomorrowYouWillDiscoverNewExercises')}
            </p>
          ) : (
            <p className="max-w-86 typo-xs mt-2">
              {t('patient.afterCompletingAllExercisesLevelUp')}
            </p>
          )}
        </div>
      ) : (
        <div>
          <h1>{t('patient.noPostureScoreYet')}</h1>
          <div className="mt-2">
            <span className="typo-button text-primary cursor-pointer">{t('common.learnMore')}</span>
          </div>
        </div>
      )}
    </div>
  );
};
