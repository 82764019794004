import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import {CheckCircleIcon} from "../../icons";

export interface IHealthierPostureIntroductionProps {
  className?: string;
}

const introDescriptions = [0, 1, 2, 3];

export const HealthierPostureIntroduction: FC<IHealthierPostureIntroductionProps> = ({
  className,
}) => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <h2 className="max-w-60">{t('patient.introductionTitle')}</h2>
      <div className="gradient-transparent-body1 mt-6">
        <img className="w-full" src="/assets/images/onboard/onboard1.png" alt="" />
      </div>
      <div className="card1 text-typo1 !border-0 px-6 pt-6 pb-10 mt-6">
        {introDescriptions.map((option) => (
          <div key={option} className="description-item flex items-center mt-4">
            <CheckCircleIcon size={24} />
            <div className="typo-sm ml-2">{t(`patient.introductionSteps.${option}`)}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
