import moment from "moment";
import {STATUS} from "../enums";

export class ChallengeModel {
  id: string;
  status: STATUS;
  level: number;
  trainLevel: number;
  createdAt: string;
  updatedAt: string;

  constructor(init?: Partial<ChallengeModel>) {
    const data = {
      id: null,
      level: 1,
      trainLevel: 3,
      ...init,
    };

    this.id = data.id;
    this.status = data.status;
    this.level = data.level;
    this.trainLevel = data.trainLevel;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }

  isExpired() {
    return moment(this.createdAt).add(28, 'days').isBefore();
  }
}
