import React, {FC} from "react";
import {Icon} from "./Icon";
import {IIconProps} from "./types";

export const PauseIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={32} baseHeight={32} {...props}>
    {(width, height, color) => (
      <svg width={width} height={height} viewBox="0 0 32 32">
        <rect x="9.83325" y="6.66669" width="2.66667" height="18.6667" rx="1" fill={color} />
        <rect x="20.5" y="6.66669" width="2.66667" height="18.6667" rx="1" fill={color} />
      </svg>
    )}
  </Icon>
);
