import type {PayloadAction} from "@reduxjs/toolkit";
import {createSlice} from "@reduxjs/toolkit";
import {CONFIG} from "../../constants";
import {LOADING_STATUS} from "../../resources/enums";
import {UserModel} from "../../resources/models";

export interface IAuthState {
	token?: string | null;
	account?: UserModel;
	profileLoadingStatus: LOADING_STATUS;
}

const initialState: IAuthState = {
	token: undefined,
	profileLoadingStatus: LOADING_STATUS.NONE,
};

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setToken: (state, action: PayloadAction<string | null>) => {
			state.token = action.payload;
			if (state.token) {
				localStorage.setItem(CONFIG.TOKEN_KEY, state.token);
			} else {
				localStorage.removeItem(CONFIG.TOKEN_KEY);
				state.account = null;
			}
		},
		setAccount: (state, action: PayloadAction<UserModel | null>) => {
			state.account = action.payload;
		},
		patchAccount: (state, action: PayloadAction<Partial<UserModel>>) => {
			state.account = {
				...state.account,
				...action.payload,
			};
		},
		setProfileLoadingStatus: (state, action: PayloadAction<LOADING_STATUS>) => {
			state.profileLoadingStatus = action.payload;
		},
	},
});

export const {
	setToken,
	setAccount,
	patchAccount,
	setProfileLoadingStatus,
} = authSlice.actions;

export default authSlice.reducer;
