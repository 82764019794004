export enum ISSUE_TYPE {
  SHOULDER_PAIN_RELEASE = 'SHOULDER_PAIN_RELEASE',
  NECK_TENSION_RELEASE = 'NECK_TENSION_RELEASE',
  LOW_BACK_RELEASE = 'LOW_BACK_RELEASE',
  POSTURE_BOOSTER = 'POSTURE_BOOSTER',
}

export const ISSUE_TYPE_VALUES = Object.values(ISSUE_TYPE);

export const ISSUE_TYPES = [
  { text: 'Shoulder pain release', value: ISSUE_TYPE.SHOULDER_PAIN_RELEASE },
  { text: 'Neck tension release', value: ISSUE_TYPE.NECK_TENSION_RELEASE },
  { text: 'Low back release', value: ISSUE_TYPE.LOW_BACK_RELEASE },
  { text: 'Posture booster', value: ISSUE_TYPE.POSTURE_BOOSTER },
];
