import React, {FC, ReactNode, useCallback, useMemo, useRef, useState} from "react";
import classNames from "classnames";
import {Link} from "react-router-dom";
import ReactDropzone from "react-dropzone";
import {Button} from "../Button";
import {CameraTimer} from "../CameraTimer";
import {Timer} from "../Timer";
import {IWebcamRef, Webcam} from "../Webcam";
import {ImageIcon, InfoCircleIcon, ShootIcon} from "../../icons";
import {IPhoto} from "../../resources/interfaces";
import {cropImageFromGeometry} from "../../utils/helpers";

export interface IPhotoScannerProps {
  className?: string;
  header?: ReactNode;
  onTakePhoto?: (photo: IPhoto) => void;
}

export const PhotoScanner: FC<IPhotoScannerProps> = ({
  className,
  header,
  onTakePhoto,
}) => {
  const ref = useRef<HTMLDivElement>();
  const camRef = useRef<IWebcamRef>();
  const [cameraEnabled, setCameraEnabled] = useState(false);
  const [error, setError] = useState<string>();
  const [size, setSize] = useState({ width: 0, height: 0 });
  const [maskRect, setMaskRect] = useState({ left: 0, top: 0, right: 0, bottom: 0 });
  const [timer, setTimer] = useState<number>();
  const [timerPlaying, setTimerPlaying] = useState(false);
  const [photo, setPhoto] = useState<IPhoto>();

  const onInitCamera = useCallback((ref) => {
    camRef.current = ref;
    setCameraEnabled(true);
  }, []);

  const onResize = React.useCallback(() => {
    const el = ref.current;
    if (el) {
      const width = el.clientWidth;
      const height = el.clientHeight;

      let w = width - 50;
      let h = width * 16 / 9;
      if (h > height - 250) {
        h = height - 250;
        w = h * 9 / 16;
      }

      setSize({ width, height });
      setMaskRect({
        left: Math.round((width - w) / 2),
        right: Math.round((width + w) / 2),
        top: Math.round((height - h) / 2),
        bottom: Math.round((height + h) / 2),
      });
    }
  }, []);

  React.useEffect(() => {
    onResize();

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);

  const maskSvg = useMemo(() => {
    if (!size.width || !size.height) {
      return null;
    }

    const l = maskRect.left / size.width;
    const r = maskRect.right / size.width;
    const t = maskRect.top / size.height;
    const b = maskRect.bottom / size.height;
    const rx = 10 / size.width;
    const ry = 10 / size.height;
    return (
      <svg className="absolute">
        <defs>
          <clipPath id="mask-path" clipPathUnits="objectBoundingBox">
            <path d={`M0,0 L0,1 L1,1 L1,0 L0,0
              L${l + rx},${t} L${r - rx},${t} C${r - rx},${t} ${r},${t} ${r}${t + ry}
              L${r},${t + ry} L${r},${b - ry} C${r},${b - ry} ${r},${b} ${r - rx}${b}
              L${r - rx},${b} L${l + rx},${b} C${l + rx},${b} ${l},${b} ${l}${b - ry}
              L${l}${b - ry} L${l},${t + ry} C${l},${t + ry} ${l},${t} ${l + rx}${t}
              Z`}
            />
          </clipPath>
        </defs>
      </svg>
    );
  }, [size, maskRect]);

  const onShot = () => {
    if (timer) {
      setTimerPlaying(true);
    } else {
      onTakeCroppedPhoto();
    }
  };

  const onTimeout = () => {
    setTimerPlaying(false);
    onTakeCroppedPhoto();
  };

  const onTakeCroppedPhoto = () => {
    const videoWidth = camRef.current.video.clientWidth;
    const videoHeight = camRef.current.video.clientHeight;
    const rect = {
      x: maskRect.left - (size.width - videoWidth) / 2,
      y: maskRect.top - (size.height - videoHeight) / 2,
      width: maskRect.right - maskRect.left,
      height: maskRect.bottom - maskRect.top,
    };
    cropImageFromGeometry(camRef.current.video, rect, 1, 1).then((photo) => {
      setPhoto(photo);
    });
  };

  const onUsePhoto = () => {
    if (onTakePhoto) {
      onTakePhoto(photo);
    }
  };

  const onSelectFile = (files: File[]) => {
    if (files.length) {
      setPhoto({
        blob: files[0],
        dataURL: URL.createObjectURL(files[0]),
      });
    }
  };

  return (
    <div ref={ref} className={classNames('relative flex-center', className)}>
      {!photo && (
        <>
          <Webcam
            className="w-full h-full"
            onInit={onInitCamera}
            onError={setError}
          />

          {maskSvg}
          <div
            className="absolute top-0 left-0 w-full h-full bg-black/40"
            style={{
              clipPath: 'url(#mask-path)',
              clipRule: "evenodd",
              fillRule: "evenodd"
            }}
          />

          <div
            className="absolute flex-center"
            style={{
              width: `${maskRect.right - maskRect.left - 40}px`,
              height: `${maskRect.bottom - maskRect.top - 40}px`,
            }}
          >
            <div className="absolute top-0 left-0 w-8 h-8 border-t-4 border-l-4 border-primary" />
            <div className="absolute top-0 right-0 w-8 h-8 border-t-4 border-r-4 border-primary" />
            <div className="absolute bottom-0 left-0 w-8 h-8 border-b-4 border-l-4 border-primary" />
            <div className="absolute bottom-0 right-0 w-8 h-8 border-b-4 border-r-4 border-primary" />
            <div className="absolute -top-4 w-1 h-16 bg-primary" />
            <div className="absolute -bottom-4 w-1 h-16 bg-primary" />
            <div className="absolute -left-4 w-16 h-1 bg-primary" />
            <div className="absolute -right-4 w-16 h-1 bg-primary" />
            <div className="absolute w-8 h-8 border-4 border-primary rounded-full" />
          </div>
        </>
      )}

      {error && (
        <div className="absolute card1 px-5 py-4">
          {error}
        </div>
      )}

      {cameraEnabled && (
        <>
          <div className="absolute top-8 left-0 w-full flex items-center px-10">
            {header}
            <CameraTimer
              className={classNames('ml-auto', photo && 'opacity-0 pointer-events-none')}
              value={timer}
              options={[5, 10]}
              onChanges={setTimer}
            />
          </div>

          {photo ? (
            <div className="w-full h-full flex-center flex-col pt-28 pb-10 px-10">
              <div className="flex-center h-[60vh] flex-1 my-auto">
                <img className="max-w-full max-h-full rounded-lg" src={photo.dataURL} alt="" />
              </div>
              <div className="flex items-center gap-6 mt-10">
                <Button className="!w-43" theme="secondary" onClick={() => setPhoto(undefined)}>
                  <i className="fa fa-times text-2xl mr-2" /> Retake
                </Button>
                <Button className="!w-43" theme="primary" onClick={onUsePhoto}>
                  <i className="fa fa-check text-2xl mr-2" /> Use Photo
                </Button>
              </div>
            </div>
          ) : (
            <>
              {timer && (
                <div className="absolute">
                  <Timer
                    className="text-white text-64p font-semibold"
                    time={timer}
                    playing={timerPlaying}
                    format="s"
                    onFinished={onTimeout}
                  />
                </div>
              )}

              <div className="absolute bottom-10 flex-center">
                <ReactDropzone
                  accept={{
                    'image/jpeg': ['.jpeg', '.png']
                  }}
                  onDrop={onSelectFile}
                >
                  {(state) => (
                    <div {...state.getRootProps()}>
                      <input {...state.getInputProps()} />
                      <div className="w-12 h-12 flex-center bg-overlay1 text-white rounded-full hover:scale-110 cursor-pointer transition-all">
                        <ImageIcon size={24} />
                      </div>
                    </div>
                  )}
                </ReactDropzone>
                <ShootIcon className="text-white hover:scale-110 mx-12 cursor-pointer transition-all" size={70} onClick={onShot} />
                <Link className="w-12 h-12 flex-center bg-overlay1 text-white rounded-full hover:scale-110 opacity-0 pointer-events-none cursor-pointer transition-all" to="/scanner/photo-tips">
                  <InfoCircleIcon size={24} />
                </Link>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};
