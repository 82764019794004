import React from "react";
import {useSelector} from "react-redux";
import {LoadingSpinner} from "../LoadingSpinner";
import {RootState} from "../../redux/store";

export const SpinnerContainer = () => {
  const { loadingCount } = useSelector((state: RootState) => state.spinner);
  if (loadingCount <= 0) {
    return null;
  }

  return (
    <div className="fixed top-0 left-0 w-screen h-screen z-max flex-center bg-overlay1">
      <LoadingSpinner />
    </div>
  );
};
