import React, {FC} from "react";
import classNames from "classnames";

export interface IProgressBarProps {
  className?: string;
  theme?: 'primary';
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  rounded?: boolean;
  percent: number;
}

export const ProgressBar: FC<IProgressBarProps> = ({
  className,
  theme = 'primary',
  size = 'md',
  rounded = true,
  percent,
}) => {
  const progressBarClass = classNames(
    'w-full transition-all overflow-hidden',
    theme === 'primary' && 'bg-progress-trail text-typo1',
    size === 'sm' && 'h-1',
    rounded && 'rounded-xl',
    className,
  );

  const progressClass = classNames(
    'h-full transition-all',
    theme === 'primary' && 'bg-progress-path',
    rounded && 'rounded-xl',
  );

  return (
    <div className={progressBarClass}>
      <div className={progressClass} style={{ width: `${percent}%` }} />
    </div>
  );
};
