import React, {FC} from "react";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import {Button, Popup} from "../../components";
import {ScannerIcon} from "../../icons";

export interface IScanNeedPopupProps {
  className?: string;
  onContinue(): void;
  onClose(): void;
}

export const ScanNeedPopup: FC<IScanNeedPopupProps> = ({
  className,
  onContinue,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <Popup
      contentClass={classNames('text-typo1', className)}
      width="21rem"
      disableBackdrop
      onClose={onClose}
    >
      <h1 className="flex items-center"><ScannerIcon className="mr-3" size={28} /> {t('common.scanner')}</h1>
      <p className="mt-1">{t('patient.aiWillAnalyzeYourBody')}!</p>
      <p className="text-typo2 mt-4">{t('patient.youCanSeePhotoTips')}.</p>
      <Button className="mt-4" theme="primary" fullWidth onClick={onContinue}>
        {t('common.continue')}
      </Button>
    </Popup>
  );
};
