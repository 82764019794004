import {FC, useEffect, useState} from "react";
import {CONFIG} from "../constants";
import {fetchAccountProfile} from "../redux/apis";
import {setToken} from "../redux/reducers/auth.reducer";
import {useDispatch, useSelector} from "../redux/store";

export interface IAuthProviderProps {
  children: JSX.Element;
}

export const AuthProvider: FC<IAuthProviderProps> = ({
  children,
}) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem(CONFIG.TOKEN_KEY);
    if (!token) {
      setLoading(false);
      return;
    }
    dispatch(setToken(token));
  }, [dispatch]);

  useEffect(() => {
    if (!token) {
      return;
    }

    setLoading(true);
    dispatch(fetchAccountProfile({ force: true, showError: false })).catch(() => {
      dispatch(setToken(null));
    }).finally(() => {
      setLoading(false);
    });
  }, [dispatch, token]);

  if (loading) {
    return null;
  }

  return children;
};
