import React, {FC} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import classNames from "classnames";
import {Button} from "../../components";
import {BarChartOutlineIcon, ScanFocusIcon} from "../../icons";
import {useSelector} from "../../redux/store";

export interface IExerciseDailyProgramProps {
  className?: string;
  canTrain?: boolean;
  onStartTraining(): void;
}

export const ExerciseDailyProgram: FC<IExerciseDailyProgramProps> = ({
  className,
  canTrain,
  onStartTraining,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { scan, trainStatus } = useSelector((state) => state.patient);
  const todayLevel = trainStatus?.todayLevel || 1;
  const noScan = !scan;

  const onScan = () => {
    navigate('/scanner', { replace: true });
  };

  return (
    <div className={className}>
      <h2>{t('patient.yourDailyProgram')}</h2>
      <div className={classNames('min-h-28 card2 flex items-center mt-4', noScan && 'shadow')}>
        {noScan ? (
          <div className="md:flex flex-1 items-center justify-between px-6 py-3">
            <div className="shrink-0">
              <div className="flex items-center text-typo1">
                <ScanFocusIcon size={24} />
                <h6 className="ml-6">{t('patient.goScan')}</h6>
              </div>
              <p className="md:max-w-64 mt-2 md:mt-3">
                {t('patient.needToScanToGetExercises')}!
              </p>
            </div>
            <Button className="max-md:mt-2 md:ml-3" onClick={onScan}>
              Scan
            </Button>
          </div>
        ) : (
          <>
            <div className="w-28 h-28 flex-center shrink-0">
              <img className="max-w-full max-h-full" src="/assets/images/patient/user-avatar.png" alt="" />
            </div>
            <div className="md:flex flex-1 items-center justify-between px-6 py-3">
              <div className="max-md:flex items-center shrink-0">
                <div className="flex items-center text-primary">
                  <BarChartOutlineIcon size={16} />
                  <span className="typo-xs ml-1.5">{t('common.level#', { level: todayLevel })}</span>
                </div>
                <h6 className="md:mt-2 max-md:ml-6">{t('patient.todayTraining')}</h6>
              </div>
              <Button
                className="max-md:mt-2 md:ml-3"
                disabled={!canTrain}
                onClick={onStartTraining}
              >
                {t('patient.startTraining')}
              </Button>
            </div>
          </>
        )}
      </div>
      <p className="mt-4">{t('patient.solveYourDailyProgram')}</p>
    </div>
  );
};
