import React, {FC, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, IButtonProps} from "../../components";
import {SharePhotosPopup} from "../SharePhotosPopup";
import {STATUS} from "../../resources/enums";
import {ScanModel} from "../../resources/models";
import {ToastService} from "../../services";

export interface ISharePhotosButtonProps extends IButtonProps {
  scan: ScanModel;
}

export const SharePhotosButton: FC<ISharePhotosButtonProps> = ({
  scan,
  ...buttonProps
}) => {
  const { t } = useTranslation();
  const [showSharePopup, setShowSharePopup] = useState(false);

  const onSharePhotos = () => {
    if (scan?.status !== STATUS.COMPLETED) {
      ToastService.error('toast.uploadPhotosFirst');
      return;
    }
    setShowSharePopup(true);
  };

  return (
    <>
      <Button
        variant="text"
        {...buttonProps}
        onClick={onSharePhotos}
      >
        {t('common.share')}
      </Button>

      {showSharePopup && (
        <SharePhotosPopup scan={scan} onClose={() => setShowSharePopup(false)} />
      )}
    </>
  );
};
