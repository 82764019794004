import React, {FC} from "react";
import moment from "moment";
import classNames from "classnames";
import {Trans} from "react-i18next";
import {useSelector} from "../../redux/store";

export interface IHowToWinChallengeProps {
  className?: string;
}

export const HowToWinChallenge: FC<IHowToWinChallengeProps> = ({
  className,
}) => {
  const { challenge } = useSelector((state) => state.patient);

  const level = challenge ? challenge.level - 1 : 0;
  const botLevel = challenge ? moment().diff(challenge.createdAt, 'days') : 0;

  return (
    <ul className={classNames('text-typo1 typo-sm list-disc', className)}>
      <li>
        <Trans i18nKey="patient.challengeGuides.solveWorkout" components={[<b className="text-primary-dark" />]} />
      </li>
      {level > botLevel && (
        <li>
          <Trans i18nKey="patient.challengeGuides.have#daysInAdvance" count={level - botLevel} components={[<b className="text-primary-dark" />]} />
        </li>
      )}
      <li>
        <Trans i18nKey="patient.challengeGuides.scanEveryWeek" components={[<b className="text-primary-dark" />]} />
      </li>
      <li>
        <Trans i18nKey="patient.challengeGuides.afterSuccess" components={[<b className="text-primary-dark" />]} />
      </li>
    </ul>
  );
};
