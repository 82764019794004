import React, {FC} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ScanPhoto} from "../ScanPhoto";
import {PHOTO_TYPE, SCAN_TYPE, STATUS} from "../../resources/enums";
import {ScanModel} from "../../resources/models";
import {ScansService, ToastService} from "../../services";

export interface IScanPhotosViewProps {
  className?: string;
  scan?: ScanModel;
  link?: boolean;
}

export const ScanPhotosView: FC<IScanPhotosViewProps> = ({
  className,
  scan,
  link,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onScanPhoto = async (type: PHOTO_TYPE) => {
    if (!link) {
      return;
    }

    let currentScan = scan;
    if (!currentScan) {
      currentScan = await ScansService.create({ type: SCAN_TYPE.POSTURE }).catch((err) => {
        ToastService.showHttpError(err, 'toast.creatingScanFailed');
        return undefined;
      });
    }

    if (!currentScan) {
      return;
    }

    if (currentScan.status === STATUS.PENDING) {
      navigate(`/scanner/${currentScan.id}/photos/${type}`);
    } else if (currentScan.status === STATUS.COMPLETED) {
      navigate(`/scanner/result/deviation?type=${type}`);
    }
  };

  return (
    <div className={className}>
      <div className="grid grid-cols-3 gap-4">
        <ScanPhoto
          scan={scan}
          type={PHOTO_TYPE.FRONT}
          aspectRatio={2/3}
          label={`1. ${t('common.front')}`}
          onClick={link ? () => onScanPhoto(PHOTO_TYPE.FRONT) : undefined}
        />
        <ScanPhoto
          scan={scan}
          type={PHOTO_TYPE.SIDE}
          aspectRatio={2/3}
          label={`2. ${t('common.side')}`}
          onClick={link ? () => onScanPhoto(PHOTO_TYPE.SIDE) : undefined}
        />
        <ScanPhoto
          scan={scan}
          type={PHOTO_TYPE.SIT}
          aspectRatio={2/3}
          label={`3. ${t('common.backSitting')}`}
          onClick={link ? () => onScanPhoto(PHOTO_TYPE.SIT) : undefined}
        />
      </div>
    </div>
  );
};
