import React, {FC} from "react";
import {Icon} from "./Icon";
import {IIconProps} from "./types";

export const InfoCircleIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={16} baseHeight={16} {...props}>
    {(width, height, color) => (
      <svg width={width} height={height} viewBox="0 0 16 16">
        <path
          fill={color}
          d="M8 0C6.41775 0 4.87104 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346629 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9977 5.87897 15.1541 3.84547 13.6543 2.34568C12.1545 0.845885 10.121 0.00229405 8 0ZM8 14.6667C6.68146 14.6667 5.39253 14.2757 4.2962 13.5431C3.19987 12.8106 2.34539 11.7694 1.84081 10.5512C1.33622 9.33305 1.2042 7.9926 1.46144 6.6994C1.71867 5.40619 2.35361 4.2183 3.28596 3.28595C4.21831 2.3536 5.4062 1.71867 6.6994 1.46143C7.99261 1.2042 9.33305 1.33622 10.5512 1.8408C11.7694 2.34539 12.8106 3.19987 13.5431 4.2962C14.2757 5.39253 14.6667 6.68146 14.6667 8C14.6647 9.76751 13.9617 11.4621 12.7119 12.7119C11.4621 13.9617 9.76752 14.6647 8 14.6667Z"
        />
        <path
          fill={color}
          d="M8 6.66667H7.33334C7.15653 6.66667 6.98696 6.7369 6.86193 6.86193C6.73691 6.98695 6.66667 7.15652 6.66667 7.33333C6.66667 7.51014 6.73691 7.67971 6.86193 7.80474C6.98696 7.92976 7.15653 8 7.33334 8H8V12C8 12.1768 8.07024 12.3464 8.19527 12.4714C8.32029 12.5964 8.48986 12.6667 8.66667 12.6667C8.84348 12.6667 9.01305 12.5964 9.13807 12.4714C9.2631 12.3464 9.33334 12.1768 9.33334 12V8C9.33334 7.64638 9.19286 7.30724 8.94281 7.05719C8.69276 6.80714 8.35363 6.66667 8 6.66667Z"
        />
        <path
          fill={color}
          d="M8 5.33333C8.55229 5.33333 9 4.88562 9 4.33333C9 3.78105 8.55229 3.33333 8 3.33333C7.44772 3.33333 7 3.78105 7 4.33333C7 4.88562 7.44772 5.33333 8 5.33333Z"
        />
      </svg>
    )}
  </Icon>
);
