import React from 'react';
import {Route, Routes} from "react-router-dom";
import {Exercises} from "./Home";
import {FirstAid} from "./FirstAid";
import {Challenge} from "./Challenge";
import {LearnExercise} from "./LearnExercise";
import {Tutorial} from "./Tutorial";

export const ExercisesRouting = () => {
  return (
    <Routes>
      <Route path="/" element={<Exercises />} />
      <Route path="/first-aids/:type" element={<FirstAid />} />
      <Route path="/challenge" element={<Challenge />} />
      <Route path="/:id/learn" element={<LearnExercise />} />
      <Route path="/tutorial" element={<Tutorial />} />
    </Routes>
  );
};

