import {GENDER, ROLE, USER_QUIZ_GOAL, USER_QUIZ_LIFESTYLE, USER_QUIZ_PROBLEM} from "../enums";
import {ChallengeModel} from "./challenge.model";
import {ScanModel} from "./scan.model";
import {SubscriptionModel} from "./subscription.model";
import {PartnerModel} from "./partner.model";

export class UserModel {
  id: string;
  status: string;
  role: ROLE;
  gender: GENDER;
  name: string;
  doctorName: string;
  birth: number;
  level: number;
  trainLevel: number;
  deviceToken?: string;
  language?: string;
  country?: string;
  quizGoal: USER_QUIZ_GOAL;
  quizBackDisorders: USER_QUIZ_PROBLEM;
  quizLifestyle: USER_QUIZ_LIFESTYLE;
  appVersion?: string;
  email?: string;
  challenge?: ChallengeModel;
  scan?: ScanModel;
  spineScan?: ScanModel;
  subscription?: SubscriptionModel;
  partner?: PartnerModel;
  isFirstLogin?: boolean;

  constructor(init?: Partial<UserModel>) {
    const data = {
      id: null,
      gender: GENDER.MALE,
      ...init,
    };

    this.id = data.id;
    this.status = data.status;
    this.role = data.role;
    this.gender = data.gender;
    this.name = data.name;
    this.doctorName = data.doctorName;
    this.birth = data.birth;
    this.level = data.level;
    this.trainLevel = data.trainLevel;
    this.deviceToken = data.deviceToken;
    this.language = data.language;
    this.country = data.country;
    this.quizGoal = data.quizGoal;
    this.quizBackDisorders = data.quizBackDisorders;
    this.quizLifestyle = data.quizLifestyle;
    this.appVersion = data.appVersion;
    this.email = data.email;
    this.isFirstLogin = data.isFirstLogin;

    if (data.challenge) {
      this.challenge = new ChallengeModel(data.challenge);
    }
    if (data.scan) {
      this.scan = new ScanModel(data.scan);
    }
    if (data.spineScan) {
      this.spineScan = new ScanModel(data.spineScan);
    }
    if (data.subscription) {
      this.subscription = new SubscriptionModel(data.subscription);
    }
    if (data.partner) {
      this.partner = new PartnerModel(data.partner);
    }
  }
}
