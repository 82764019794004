import React, {useMemo} from "react";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Button, Image, ProgressBar} from "../../../../components";
import {PatientLayout} from "../../../../layouts";
import {HealthIssues, PageBackButton} from "../../../../parts";
import {useSelector} from "../../../../redux/store";

export const IssueDetail = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { scan, issues, trainStatus } = useSelector((root) => root.patient);
  const remainTrainCount = trainStatus?.remainTrainCount || 0;

  const issue = useMemo(() => (
    issues.find((item) => item.id === id)
  ), [id, issues]);

  const progress = issue?.getProbability() || 0;
  const content = issue?.getLocalizedContent();

  const sidebar = (
    <>
      <h2>{t('patient.detectedIssues')}</h2>
      {scan && (
        <HealthIssues className="mt-8" />
      )}
    </>
  );

  return (
    <PatientLayout sidebar={sidebar}>
      <PageBackButton path="/scanner/result" />

      {issue && (
        <div className="relative">
          <h1 className="mt-8">{content?.title}</h1>

          {!content?.video ? (
            <div className="mt-6">
              {!!issue?.scans?.length && (
                <div className="grid xl:grid-cols-2 gap-6 mb-6">
                  <div>
                    <div className="flex items-center justify-between text-primary typo-sm">
                      <span>{t('patient.healthIssues.issueProbability')}</span>
                      <span>{Math.floor(progress)}%</span>
                    </div>
                    <ProgressBar theme="primary" percent={progress} size="sm" />
                  </div>
                </div>
              )}
              <div className="grid xl:grid-cols-2 gap-6">
                <Image
                  wrapperClass="rounded-10p"
                  placeholderClass="card1 w-full h-full"
                  src={issue.getCover()?.src}
                  spinner
                  aspectRatio={1}
                />
                <div>
                  <h6>{t('patient.possibleTriggeredSymptoms')}:</h6>
                  <ul className="list-disc typo-sm mt-6">
                    {(content?.featureSymptoms || []).map((item, i) => (
                      <li key={i}>{item}</li>
                    ))}
                  </ul>
                  <Button className="mt-6" theme="primary" fullWidth disabled={!remainTrainCount} link="/exercises">
                    {t('patient.goTraining')}
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div className="max-xl:max-w-120 max-h-[60vh] mt-6 max-xl:mx-auto">
              <div className="flex-center">
                <video className="max-w-full max-h-full" src={content?.video} controls />
              </div>
              <p className="text-typo1 mt-6">
                {content?.description}
              </p>
            </div>
          )}
        </div>
      )}
    </PatientLayout>
  );
};
