export enum LANGUAGE {
    EN = 'en', // English
    DE = 'de', // German
    UK = 'uk', // Ukrainian
    RU = 'ru', // Russian
    HE = 'he', // Hebrew
    FR = 'fr', // French
    PL = 'pl', // Polish
    ES = 'es', // Spanish
    PT = 'pt', // Portuguese
    RO = 'ro', // Romanian
    IT = 'it', // Italian
    HU = 'hu', // Hungarian
    EL = 'el', // Greek
    CS = 'cs', // Czech
    AR = 'ar', // Arabic
}
