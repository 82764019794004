import React, {FC, useMemo, useState} from "react";
import classNames from "classnames";
import {useDispatch, useSelector} from "react-redux";
import {Link, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Logo} from "../../../components";
import {
  AccountSettingsOutlineIcon,
  ChatIcon,
  ExerciseIcon,
  HomeIcon,
  IIconProps,
  LogoutIcon,
  ScannerIcon,
  UserIcon
} from "../../../icons";
import {setToken} from "../../../redux/reducers/auth.reducer";
import {getAccount} from "../../../redux/selectors";
import {MENU} from "../../../resources/enums";

export interface ISidebarProps {
  className?: string;
}

const menus: {
  text: string;
  key: MENU;
  icon: FC<IIconProps>,
  path: string;
  disabled?: boolean;
  notification?: boolean;
}[] = [
  { text: 'common.home', key: MENU.HOME, icon: HomeIcon, path: '/home' },
  { text: 'common.scanner', key: MENU.SCANNER, icon: ScannerIcon, path: '/scanner' },
  { text: 'common.exercises', key: MENU.EXERCISES, icon: ExerciseIcon, path: '/exercises' },
  // { text: 'common.chat', key: MENU.DOCTOR_SUPPORT, icon: ChatIcon, path: '/chat', notification: true, disabled: true },
  { text: 'common.myAccount', key: MENU.MY_ACCOUNT, icon: AccountSettingsOutlineIcon, path: '/my-account' },
];

export const Sidebar: FC<ISidebarProps> = ({
  className,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector(getAccount);
  const [opened, setOpened] = useState(false);

  const activeMenu = useMemo(() => {
    return menus.find((item) => location.pathname.startsWith(item.path));
  }, [location]);

  const onLogout = () => {
    dispatch(setToken(null));
  };

  return (
    <>
      <div className={classNames(
        'fixed top-0 left-0 z-[5] w-59 h-screen flex flex-col justify-between',
        'bg-sidebar bg-full overflow-auto transition-all px-6 pt-10 pb-8',
        'max-sm:left-unset max-sm:-right-60 max-sm:shadow-right-sidebar',
        opened && 'max-sm:right-0',
        className,
      )}>
        <Logo link="/" />

        <div className="flex items-center text-sidebar-title typo-button mt-12">
          <UserIcon size={24} />
          <span className="ml-2">{user.name}</span>
        </div>

        <div className="my-auto">
          {menus.map((item) => (
            <Link
              key={item.key}
              className={classNames(
                'relative flex items-center typo-button py-1 my-4 transition-all',
                item === activeMenu ? 'text-sidebar-active' : 'text-sidebar-menu',
                item.disabled ? 'opacity-60 cursor-default' : 'hover:text-primary cursor-pointer',
              )}
              to={item.disabled ? '' : item.path}
            >
              <item.icon size={24} />
              <span className="ml-4">{t(item.text)}</span>
              {item.notification && (
                <span className="absolute top-3 left-5 w-2 h-2 bg-danger rounded-full" />
              )}
            </Link>
          ))}
        </div>

        <div
          className="flex items-center typo-button text-danger py-1 mt-8 cursor-pointer transition-all"
          onClick={onLogout}
        >
          <LogoutIcon size={24} />
          <span className="ml-4">{t('common.logout')}</span>
        </div>
      </div>

      <div
        className={classNames(
          'fixed top-0 left-0 z-[4] w-screen h-screen bg-overlay1 opacity-0 pointer-events-none transition-all',
          opened && 'max-sm:opacity-100 max-sm:pointer-events-auto',
        )}
        onClick={() => setOpened(false)}
      />

      <div
        className="fixed top-2 right-2 z-[4] sm:hidden text-xl p-1 cursor-pointer transition-all"
        onClick={() => setOpened(!opened)}
      >
        <i className="fa fa-bars" />
      </div>
    </>
  );
};
