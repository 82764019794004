import React, {FC} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import moment from "moment";
import classNames from "classnames";
import {Button} from "../../components";
import {FlagIcon, RunIcon} from "../../icons";
import {useSelector} from "../../redux/store";

export interface IExerciseChallengeCardProps {
  className?: string;
}

const challengeSteps = [
  { week: 1, showLabel: true },
  { week: 2, showLabel: true },
  { week: 3, showLabel: true },
  { week: 4, showLabel: true },
  { week: 5 },
];

export const ExerciseChallengeCard: FC<IExerciseChallengeCardProps> = ({
  className,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { challenge, trainStatus } = useSelector((state) => state.patient);
  const currentLevel = trainStatus?.currentLevel || 1;

  const level = Math.min(currentLevel, 28);
  let botLevel = 1;
  if (challenge) {
    botLevel = Math.min(moment().diff(challenge.createdAt, 'days'), 28);
  }
  const isFinished = level >= 28;
  const isFailed = botLevel >= 28 && level < 28;

  const onStart = () => {
    navigate('/scanner');
  };

  return (
    <div className={classNames('card1 px-6 py-5', className)}>
      <h6>{t('patient.28DaysChallenge')}</h6>
      {isFinished ? (
        <div className="text-center">
          <hr />
          <h3 className="text-primary">{t('common.hurra')}</h3>
          <p className="typo-xs mt-4">
            {t('patient.completedChallenge')}<br/>
            {t('patient.tomorrowYouWillDiscoverNewExercises')}
          </p>
        </div>
      ) : isFailed ? (
        <div className="text-center">
          <h3 className="text-primary mt-2">{t('common.ohNo')}...</h3>
          <p className="typo-xs mt-4">
            {t('patient.failedChallenge')}.
          </p>
          <Button className="mt-2" theme="secondary" fullWidth onClick={onStart}>
            {t('common.restart')}
          </Button>
        </div>
      ) : (
        <div>
          <div className="mt-2">
            <div className="relative flex items-center text-dark-blue ml-1 mr-4">
              <FlagIcon className="text-primary ml-auto -mr-4" size={24} />
              <RunIcon
                className={classNames('absolute text-typo1 -ml-2', botLevel === level && '-translate-x-[1.5px]')}
                size={botLevel === level ? 27 : 24}
                style={{ left: `${botLevel / 28 * 100}%` }}
              />
              <RunIcon
                className="absolute text-primary -ml-2"
                size={24}
                style={{ left: `${level / 28 * 100}%` }}
              />
            </div>
            <div className="relative flex items-center mt-2 ml-2 mr-4">
              {challengeSteps.map((step, i) => (
                <React.Fragment key={i}>
                  <div className={classNames(
                    'relative z-[2] w-1.5 h-1.5 shrink-0 bg-card1 border border-primary rounded-full',
                    i * 7 <= level && 'bg-primary',
                  )} />
                  {(i < challengeSteps.length - 1) && (
                    <div className="flex-1 border border-dashed border-primary" />
                  )}
                </React.Fragment>
              ))}
              <div className="absolute left-0 border border-primary" style={{ width: `${level / 28 * 100}%` }} />
            </div>
            <div className="flex items-center justify-between mt-2 ml-2 mr-5">
              {challengeSteps.map((step) => (
                <div key={step.week} className="typo-2xs">
                  {step.showLabel ? t('common.week#', { week: step.week }) : ''}
                </div>
              ))}
            </div>
          </div>
          {!challenge && (
            <Button className="mt-3" theme="secondary" fullWidth onClick={onStart}>
              {t('common.start')}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
