import {createAsyncThunk} from "@reduxjs/toolkit";
import {fetchScans} from "./patient.apis";
import {setAccount, setProfileLoadingStatus} from "../reducers/auth.reducer";
import {setChallenge, setScan, setSpineScan} from "../reducers/patient.reducer";
import {LOADING_STATUS, SCAN_TYPE} from "../../resources/enums";
import {UserModel} from "../../resources/models";
import {AuthService, ToastService} from "../../services";
import {RootState} from "../store";
import {setCurrentLanguage} from "../../utils/helpers";
import {CONFIG} from "../../constants";

export const fetchAccountProfile = createAsyncThunk<UserModel, {
  force?: boolean;
  showSpinner?: boolean;
  showError?: boolean;
}>('patient/fetchAccountProfile', async (arg, api) => {
  api.dispatch(setProfileLoadingStatus(LOADING_STATUS.PENDING));
  return AuthService.getProfile(arg.showSpinner)
    .then(async (user) => {
      if (user.partner?.labelCode !== CONFIG.THEME) {
        throw new Error('toast.loadingAccountProfileFailed');
      }

      api.dispatch(setAccount(user));
      api.dispatch(setScan(user.scan));
      api.dispatch(setSpineScan(user.spineScan));
      api.dispatch(setChallenge(user.challenge));
      api.dispatch(setProfileLoadingStatus(LOADING_STATUS.SUCCESS));

      setCurrentLanguage(user.language);

      if (!user.scan) {
        await api.dispatch(fetchScans({ query: { type: SCAN_TYPE.POSTURE }, showError: false, showSpinner: false }));
      }
      return user;
    })
    .catch((err) => {
      if (arg.showError ?? true) {
        ToastService.showHttpError(err, 'toast.loadingAccountProfileFailed');
      }
      api.dispatch(setProfileLoadingStatus(LOADING_STATUS.FAILED));
      throw err;
    });
}, {
  condition(arg, api) {
    const root = api.getState() as RootState;
    const { profileLoadingStatus } = root.auth;
    return arg.force || profileLoadingStatus === LOADING_STATUS.NONE;
  }
});
