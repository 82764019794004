export enum PHOTO_TYPE {
  FRONT = 'front',
  SIDE = 'side',
  SIT = 'sit',
}

export const PHOTO_TYPE_VALUES = Object.values(PHOTO_TYPE);

export const PHOTO_TYPES = [
  { text: 'common.front', value: PHOTO_TYPE.FRONT },
  { text: 'common.side', value: PHOTO_TYPE.SIDE },
  { text: 'common.backSitting', value: PHOTO_TYPE.SIT },
];
