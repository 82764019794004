import React, {FC} from "react";
import {Icon} from "./Icon";
import {IIconProps} from "./types";

export const HomeIcon: FC<IIconProps> = (props) => (
  <Icon baseWidth={24} baseHeight={24} {...props}>
    {(width, height, color) => (
      <svg width={width} height={height} viewBox="0 0 24 24">
        <path
          fill={color}
          d="M23.121 9.05305L15.536 1.4625C14.5973 0.52593 13.3257 0 12 0C10.6743 0 9.40277 0.52593 8.46401 1.4625L0.879012 9.05305C0.599437 9.331 0.377782 9.66171 0.226895 10.026C0.0760072 10.3903 -0.0011104 10.781 1.20795e-05 11.1753V20.9982C1.20795e-05 21.7943 0.316083 22.5578 0.878692 23.1208C1.4413 23.6837 2.20436 24 3.00001 24H21C21.7957 24 22.5587 23.6837 23.1213 23.1208C23.6839 22.5578 24 21.7943 24 20.9982V11.1753C24.0011 10.781 23.924 10.3903 23.7731 10.026C23.6222 9.66171 23.4006 9.331 23.121 9.05305ZM22 20.9982C22 21.2636 21.8947 21.5181 21.7071 21.7057C21.5196 21.8934 21.2652 21.9988 21 21.9988H3.00001C2.7348 21.9988 2.48044 21.8934 2.29291 21.7057C2.10537 21.5181 2.00001 21.2636 2.00001 20.9982V11.1753C2.00094 10.9101 2.1062 10.656 2.29301 10.4679L9.87801 2.88035C10.4417 2.31894 11.2047 2.00377 12 2.00377C12.7953 2.00377 13.5583 2.31894 14.122 2.88035L21.707 10.4709C21.8931 10.6583 21.9983 10.9112 22 11.1753V20.9982Z"
        />
      </svg>
    )}
  </Icon>
);
