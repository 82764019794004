import { LANGUAGE } from "../resources/enums";

export const LANGUAGES_LOCALIZATION = {
  [LANGUAGE.EN]: 'English',
  [LANGUAGE.DE]: 'German',
  [LANGUAGE.UK]: 'Ukrainian',
  [LANGUAGE.RU]: 'Russian',
  [LANGUAGE.HE]: 'Hebrew',
  [LANGUAGE.FR]: 'French',
  [LANGUAGE.PL]: 'Polish',
  [LANGUAGE.ES]: 'Spanish',
  [LANGUAGE.PT]: 'Portuguese',
  [LANGUAGE.RO]: 'Romanian',
  [LANGUAGE.IT]: 'Italian',
  [LANGUAGE.HU]: 'Hungarian',
  [LANGUAGE.EL]: 'Greek',
  [LANGUAGE.CS]: 'Czech',
  [LANGUAGE.AR]: 'Arabic',
};
