import React, {FC} from "react";
import classNames from "classnames";
import {Trans, useTranslation} from "react-i18next";
import {Button, Popup} from "../../components";

export interface INewScanPopupProps {
  className?: string;
  onCreate(): void;
  onCancel(): void;
}

export const NewScanPopup: FC<INewScanPopupProps> = ({
  className,
  onCreate,
  onCancel,
}) => {
  const { t } = useTranslation();

  return (
    <Popup
      contentClass={classNames('text-typo1', className)}
      width="25rem"
      disableBackdrop
    >
      <h1>{t('patient.newScan')}</h1>
      <p className="mt-1">
        <Trans i18nKey="patient.newScanConfirmQuestion" />
      </p>
      <Button className="mt-4" fullWidth onClick={onCreate}>
        {t('patient.yesWantNewScan')}.
      </Button>
      <Button className="mt-4" variant="outlined" fullWidth onClick={onCancel}>
        {t('patient.noWantToSeeScanResult')}.
      </Button>
    </Popup>
  );
};
