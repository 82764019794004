import React from 'react';
import {Route, Routes} from "react-router-dom";
import {Scanner} from "./Home";
import {PhotoScan} from "./PhotoScan";
import {PhotoTips} from "./PhotoTips";
import {ScanResult} from "./ScanResult";
import {IssueDetail} from "./IssueDetail";
import {Deviation} from "./Deviation";

export const ScannerRouting = () => {
  return (
    <Routes>
      <Route path="/" element={<Scanner />} />
      <Route path="/:id/photos/:type" element={<PhotoScan />} />
      <Route path="/photo-tips" element={<PhotoTips />} />
      <Route path="/result" element={<ScanResult />} />
      <Route path="/issues/:id" element={<IssueDetail />} />
      <Route path="/result/deviation" element={<Deviation />} />
    </Routes>
  );
};

