import React, {FC} from "react";
import {Link} from "react-router-dom";
import {Trans, useTranslation} from "react-i18next";
import classNames from "classnames";
import {LightBulbIcon} from "../../icons";

export interface IPhotoTipsCardProps {
  className?: string;
}

export const PhotoTipsCard: FC<IPhotoTipsCardProps> = ({
  className,
}) => {
  const { t } = useTranslation();

  return (
    <Link
      className={classNames('card2 block px-6 py-3', className)}
      to="/scanner/photo-tips"
    >
      <div className="flex items-center">
        <LightBulbIcon className="text-typo1" size={24} />
        <h6 className="ml-2 mr-4">{t('patient.photoTips')}</h6>
        <i className="fa fa-angle-right p-1 ml-auto" />
      </div>
      <p className="mt-2">
        <Trans i18nKey="patient.explorePhotoTips" />.
      </p>
    </Link>
  );
};
