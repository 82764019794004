import React from "react";
import moment from "moment";
import {Trans, useTranslation} from "react-i18next";
import {Button} from "../../../components";
import {CONFIG} from "../../../constants";
import {PatientLayout} from "../../../layouts";
import {
  HealthierPostureIntroduction,
  HealthIssues,
  PostureLevel,
  ScanPhotosView
} from "../../../parts";
import {useSelector} from "../../../redux/store";
import {STATUS} from "../../../resources/enums";
import {formatDatetime} from "../../../utils/helpers";

const postureRecognitionDescriptions = [0, 1, 2];

export const PatientHome = () => {
  const { t } = useTranslation();
  const { scan } = useSelector((root) => root.patient);

  const scanReady = scan?.status === STATUS.COMPLETED;
  const remainDays = scanReady ? 7 - moment().diff(moment(scan.scannedAt), 'days') : 0;

  const renderWelcome = () => (
    <>
      <h1>{t('common.welcome')}</h1>
      <p className="typo-xs mt-2">{t('patient.welcomeText', { appName: CONFIG.APP_NAME })}</p>
    </>
  );

  const renderPostureRecognition = (className: string) => (
    <div className={className}>
      <h2>{t('patient.postureRecognitionTitle')}</h2>
      <div>
        {postureRecognitionDescriptions.map((description) => (
          <p key={description} className="mt-6">
            {t(`patient.postureRecognitionDescriptions.${description}`)}
          </p>
        ))}
      </div>
    </div>
  );

  const sidebar = !scanReady ? (
    <HealthierPostureIntroduction />
  ) : (
    <div>
      <div className="flex items-center justify-between">
        <h2>{t('patient.lastScanResult')}</h2>
        <span className="text-13p ml-4">{formatDatetime(scan.scannedAt, 'MMMM D, YYYY')}</span>
      </div>
      <ScanPhotosView className="mt-4" scan={scan} link />
      {remainDays > 0 ? (
        <Button className="mt-6" fullWidth disabled>
          <Trans i18nKey="patient.nextIn#Days" count={remainDays} />
        </Button>
      ) : (
        <Button className="mt-6" fullWidth link="/scanner">
          {t('patient.newScan')}
        </Button>
      )}
    </div>
  );

  return (
    <PatientLayout sidebar={sidebar}>
      {!scanReady ? (
        <>
          {renderWelcome()}
          <div className="mt-6">
            <Button link="/scanner">{t('patient.goScan')}</Button>
          </div>
        </>
      ) : (
        <>
          <PostureLevel />
          {renderPostureRecognition('mt-12')}
          <div className="max-w-200 mt-12">
            <h2>{t('patient.signsOfImbalances')}</h2>
            <HealthIssues className="mt-6" />
          </div>
        </>
      )}
    </PatientLayout>
  );
};

