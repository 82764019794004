import React, {FC} from "react";
import classNames from "classnames";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Button} from "../../components";

export interface IPageBackButtonProps {
  className?: string;
  path?: string;
}

export const PageBackButton: FC<IPageBackButtonProps> = ({
  className,
  path,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onBack = () => {
    if (!path) {
      navigate(-1);
    } else {
      navigate(path);
    }
  };

  return (
    <Button
      className={classNames('text-typo1 self-start', className)}
      theme="none"
      variant="text"
      onClick={onBack}
    >
      <i className="fa fa-arrow-left" />
      <span className="ml-3">{t('common.back')}</span>
    </Button>
  );
};
