import React, {useEffect, useMemo, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Navigation} from "swiper";
import {Swiper, SwiperRef, SwiperSlide} from "swiper/react";
import {Button} from "../../../../components";
import {PatientLayout} from "../../../../layouts";
import {
  PageBackButton,
  ScanPhoto,
  ScanPhotosView,
  SharePhotosButton,
} from "../../../../parts";
import {useSelector} from "../../../../redux/store";
import {PHOTO_TYPE, PHOTO_TYPE_VALUES, STATUS} from "../../../../resources/enums";

const deviations = [
  {
    type: PHOTO_TYPE.FRONT,
    fields: ['headPosition', 'shoulderPosition', 'legAxis'],
  },
  {
    type: PHOTO_TYPE.SIDE,
    fields: ['cervicalSpine', 'thoracicSpine', 'lumbarSpine'],
  },
  {
    type: PHOTO_TYPE.SIT,
    fields: ['headPosition', 'shoulderPosition', 'hipPosition'],
  },
];

export const Deviation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const { scan, trainStatus } = useSelector((root) => root.patient);
  const remainTrainCount = trainStatus?.remainTrainCount || 0;

  const [activeId, setActiveId] = useState(0);
  const swiperRef = useRef<SwiperRef>(null);

  const deviationScores = useMemo(() => scan.getDeviation(), [scan]);
  const selectedDeviation = deviations[activeId];
  const deviationScore = deviationScores[selectedDeviation.type];

  useEffect(() => {
    if (scan?.status !== STATUS.COMPLETED) {
      navigate('/scanner');
    }
  }, [navigate, scan]);

  useEffect(() => {
    const type = new URLSearchParams(location.search).get('type');
    const index = PHOTO_TYPE_VALUES.indexOf(type as PHOTO_TYPE);
    if (index !== -1) {
      setActiveId(index);
      if (swiperRef.current) {
        swiperRef.current.swiper.setProgress(0.5 * index);
      }
    }
  }, [location.search]);

  const onShowPrev = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.navigation.prevEl.click();
    }
  };

  const onShowNext = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.navigation.nextEl.click();
    }
  };

  const sidebar = (
    <>
      {scan && (
        <div>
          <div className="flex-center">
            <h2>{t('patient.yourAnalyzedPhotos')}</h2>
            <SharePhotosButton className="!h-auto !text-primary !p-0 ml-auto" scan={scan} />
          </div>

          <ScanPhotosView className="mt-4" scan={scan} link />

          <Button className="mt-6" fullWidth disabled={!remainTrainCount} link="/exercises">
            {t('patient.goTraining')}
          </Button>
        </div>
      )}
    </>
  );

  return (
    <PatientLayout sidebar={sidebar}>
      <PageBackButton />

      <h1 className="mt-8">{t('common.deviation')}</h1>

      <div className="grid xl:grid-cols-scan-result gap-6 mt-6">
        <div className="w-full max-xl:max-w-80 m-auto">
          <Swiper
            ref={swiperRef}
            className="hide-navigation"
            modules={[Navigation]}
            slidesPerView={1}
            centeredSlides
            navigation
            rewind
            onSlideChange={(e) => setActiveId(e.activeIndex)}
          >
            {deviations.map((item, i) => (
              <SwiperSlide key={item.type}>
                <ScanPhoto
                  scan={scan}
                  type={item.type}
                  aspectRatio={2/3}
                  label={`${i + 1}. ${t(`common.${item.type}`)}`}
                  labelClass="!text-sm !mt-6"
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="flex items-center mt-6">
            <Button className="mx-auto" variant="text" onClick={onShowPrev}>
              <i className="fa fa-arrow-left mr-2" /> {t('common.previous')}
            </Button>
            <Button className="mx-auto" variant="text" onClick={onShowNext}>
              {t('common.next')} <i className="fa fa-arrow-right ml-2" />
            </Button>
          </div>
        </div>

        {selectedDeviation && (
          <div className="mt-8 xl:mt-18">
            {selectedDeviation.fields.map((field) => (
              <div key={field} className="mb-6">
                <h2>{t(`common.postureScore.${field}`)}</h2>
                <div className="progress-wrapper mt-4">
                  <div className="flex items-center justify-between typo-sm text-typo1">
                    <span>{t('common.low')}</span>
                    <span>{t('common.high')}</span>
                  </div>
                  <input
                    className="gradient-range-slider xl:mt-1"
                    type="range"
                    min={0}
                    max={100}
                    value={deviationScore[field] || 0}
                    disabled
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </PatientLayout>
  );
};
