import {HttpService} from "./http.service";
import {GoogleAuthProvider, signInWithPopup, OAuthProvider, getAuth} from "firebase/auth";
import {CONFIG} from "../constants";
import {auth} from "../firebase";
import {store} from "../redux/store";
import {setToken} from "../redux/reducers/auth.reducer";
import {UserModel} from "../resources/models";
import {JwtResponse} from "../resources/interfaces";
import {ApiResponseDto} from "../resources/dtos";

export class AuthService {

  static login(email: string, password: string, hasSpinner = true): Promise<ApiResponseDto<JwtResponse>> {
    return HttpService.post('/auth/plainlogin', { email, password, partnerCode: CONFIG.THEME }, hasSpinner);
  }

  static loginWithGoogle(saveToken = true) {
    return signInWithPopup(auth, new GoogleAuthProvider())
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        if (!credential) {
          throw new Error('Login failed');
        }

        return AuthService.verifyFirebaseToken((result.user as any).accessToken, saveToken);
      });
  }

  static loginWithApple(saveToken = true) {
    const provider = new OAuthProvider('apple.com');
    return signInWithPopup(getAuth(), provider).then((result) => {
      const credential = OAuthProvider.credentialFromResult(result);
      if (!credential) {
        throw new Error('Login failed');
      }

      return AuthService.verifyFirebaseToken((result.user as any).accessToken, saveToken);
    });
  }

  static verifyFirebaseToken(token: string, saveToken = true) {
    return HttpService.post('/auth/verify', { token })
      .then((res) => {
        const accessToken = res.data.token;
        localStorage.setItem(CONFIG.TOKEN_KEY, accessToken);
        if (saveToken) {
          store.dispatch(setToken(accessToken));
        }
        return accessToken;
      });
  }

  static forgotPassword(email: string, hasSpinner = true): Promise<void> {
    return HttpService.post('/auth/forgot-password', { email }, hasSpinner);
  }

  static getProfile(hasSpinner = true): Promise<UserModel> {
    return HttpService.get('/auth/profile', {}, hasSpinner)
      .then((result) => new UserModel(result.data));
  }

  static updateProfile(data: Partial<UserModel>, hasSpinner = true) {
    return HttpService.patch('/auth/profile', data, hasSpinner)
      .then((result) => new UserModel(result.data));
  }

  static deleteProfile(hasSpinner = true): Promise<void> {
    return HttpService.delete('/auth/profile', {}, hasSpinner);
  }
}
