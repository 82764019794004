import {createSlice} from "@reduxjs/toolkit";
import {IOnboardForm} from "../../resources/interfaces";
import history from "../../utils/helpers/history";

export type IOnboardState = Partial<IOnboardForm>;

const initialState: IOnboardState = {
  step: 0,
};

export const onboardSlice = createSlice({
  name: 'onboard',
  initialState,
  reducers: {
    loadOnboardState: (state: IOnboardState) => {
      try {
        const savedState = JSON.parse(localStorage.getItem('onboard-state'));
        Object.assign(state, savedState);
        if (savedState.step > 0 && savedState.step < 5) {
          history.replace(`/onboard/step${savedState.step}`);
        } else if (savedState.step === 5) {
          history.replace('/onboard/sign-in');
        } else {
          history.replace('/onboard/getting-started');
        }
      } catch {
        history.replace('/onboard/getting-started');
      }
    },
    setOnboardState: (state: IOnboardState, action) => {
      Object.assign(state, action.payload);
      if (state.step > 5) {
        localStorage.removeItem('onboard-state');
      } else {
        localStorage.setItem('onboard-state', JSON.stringify(state));
      }
    },
  },
});

export const { loadOnboardState, setOnboardState } = onboardSlice.actions;

export default onboardSlice.reducer;
