import React, {FC, ReactNode, useMemo} from "react";
import classNames from "classnames";
import {Sidebar} from "./Sidebar";
import {CONFIG} from "../../constants";

export interface IPatientLayoutProps {
  className?: string;
  layoutClass?: string;
  contentClass?: string;
  sidebarClass?: string;
  backgroundPattern?: boolean;
  sidebar?: ReactNode;
  children: ReactNode;
}

export const PatientLayout: FC<IPatientLayoutProps> = ({
  className,
  layoutClass,
  contentClass,
  sidebarClass,
  backgroundPattern = true,
  sidebar,
  children,
}) => {
  const bgClass = useMemo(() => {
    switch (CONFIG.THEME) {
      case 'imed':
        return 'bg-main bg-full';
    }
    return '';
  }, []);

  const bgPattern = useMemo(() => {
    if (!backgroundPattern) {
      return null;
    }

    switch (CONFIG.THEME) {
      case 'mevotec':
        return (
          <img
            className="absolute top-0 -right-24 pointer-events-none"
            src="/assets/images/patient/exercise-bg-pattern-mevotec.png"
            alt=""
          />
        );

      case 'imed':
        return null;
    }
    return (
      <img
        className="absolute top-0 right-0 pointer-events-none"
        src="/assets/images/patient/exercise-bg-pattern.png"
        alt=""
      />
    );
  }, [backgroundPattern]);

  const sidebarExtraClass = useMemo(() => {
    switch (CONFIG.THEME) {
      case 'imed':
        return 'bg-transparent lg:bg-main bg-full lg:border-l border-primary-dark';
    }
    return '';
  }, []);

  return (
    <div className={classNames('relative min-h-screen bg-body1 text-typo2', bgClass, layoutClass)}>
      <Sidebar />
      <div className={classNames('lg:flex sm:pl-59 transition-all', contentClass)}>
        <div className={classNames('relative lg:w-0 lg:min-h-screen flex-1 flex flex-col bg-page-content bg-full bg-center max-lg:overflow-hidden p-6', className)}>
          {bgPattern}
          {children}
        </div>

        {sidebar && (
          <div className={classNames('lg:w-98 lg:min-h-screen bg-body2 bg-right-sidebar bg-full bg-center p-6', sidebarExtraClass, sidebarClass)}>
            {sidebar}
          </div>
        )}
      </div>
    </div>
  );
};
