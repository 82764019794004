import React, {useCallback, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Button} from "../../../../components";
import {CheckCircleIcon} from "../../../../icons";
import {PatientLayout} from "../../../../layouts";
import {ExerciseLevel, NewScanPopup, PhotoTipsCard, ScanningLoader, ScanPhotosView} from "../../../../parts";
import {createChallenge, fetchAccountProfile} from "../../../../redux/apis";
import {useDispatch, useSelector} from "../../../../redux/store";
import {setScan} from "../../../../redux/reducers/patient.reducer";
import {SCAN_TYPE, STATUS} from "../../../../resources/enums";
import {ScanModel} from "../../../../resources/models";
import {ScansService, ToastService} from "../../../../services";
import {isInDays} from "../../../../utils/helpers";

const tips = [0, 1, 2];

export const Scanner = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { scan, challenge } = useSelector((root) => root.patient);

  const [loading, setLoading] = useState(true);
  const [lastScan, setLastScan] = useState<ScanModel>();
  const [scanning, setScanning] = useState(false);
  const [showNewScanPopup, setShowNewScanPopup] = useState(() => (
    !!challenge && scan?.status === STATUS.COMPLETED && !isInDays(scan.scannedAt, 7) && isInDays(challenge.createdAt, 28)
  ));

  const onCreateScan = useCallback(async () => {
    const scan = await ScansService.create({ type: SCAN_TYPE.POSTURE }).catch((err) => {
      ToastService.showHttpError(err, 'toast.creatingScanFailed');
      return undefined;
    });
    setLastScan(scan);
  }, []);

  useEffect(() => {
    setLoading(true);
    ScansService.search({
      type: SCAN_TYPE.POSTURE,
      desc: 'createdAt',
      page: 1,
      take: 1,
    }).then(async (res) => {
      const scan = res.result[0];
      if (scan) {
        setLastScan(scan.status === STATUS.PENDING ? scan : undefined);
        if (scan.status !== STATUS.PENDING) {
          if (!challenge || !isInDays(challenge.createdAt, 28)) {
            setShowNewScanPopup(false);
            await onCreateScan();
          } else {
            setShowNewScanPopup(true);
          }
        } else {
          setShowNewScanPopup(false);
        }
      } else {
        await onCreateScan();
        setShowNewScanPopup(false);
      }
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
  }, [challenge, onCreateScan]);

  useEffect(() => {
    if (challenge && scan?.status === STATUS.COMPLETED && isInDays(scan.scannedAt, 7)) {
      navigate('/scanner/result', { replace: true });
    }
  }, [navigate, challenge, scan]);

  const onTryNewScan = () => {
    setShowNewScanPopup(false);
    if (lastScan?.status === STATUS.PENDING) {
      return;
    }
    onCreateScan();
  }

  const onCreatePhoto = async () => {
    if (!lastScan) {
      return;
    }
    navigate(`/scanner/${lastScan.id}/photos/${lastScan.getEmptyPhotos()[0]}`);
  };

  const onRedirectToScanResultPage = () => {
    navigate('/scanner/result');
  };

  const onStartScan = async () => {
    setScanning(true);

    if (!challenge || challenge.isExpired()) {
      await dispatch(createChallenge({ showError: false, showSpinner: false }));
    }

    ScansService.analyze(lastScan.id, false).then(async (newScan) => {
      dispatch(setScan(newScan));
      dispatch(fetchAccountProfile({ force: true, showSpinner: false, showError: false }));

      navigate('/scanner/result', { replace: true });
    }).catch((err) => {
      ToastService.showHttpError(err, 'toast.aiAnalyzeFailed');
    }).finally(() => {
      setScanning(false);
    });
  };

  const sidebar = (
    <>
      <PhotoTipsCard />
      <div className="mt-6">
        <h6>{t('common.photos')}</h6>
        <ScanPhotosView className="mt-4" scan={lastScan} link />
      </div>
      <div className="min-h-46 flex flex-col justify-center gap-4 bg-card1 rounded-10p p-6 mt-4">
        {tips.map((tip) => (
          <div key={tip} className="flex items-center text-typo1 typo-sm">
            <CheckCircleIcon className="mr-2" size={24} />
            {t(`patient.takingPhotoSteps.${tip}`)}
          </div>
        ))}
      </div>
      {lastScan && (
        <Button
          className="mt-6"
          theme="primary"
          fullWidth
          disabled={!lastScan.isPhotosValid()}
          onClick={onStartScan}
        >
          {t('patient.startAiScan')}
        </Button>
      )}
    </>
  );

  return (
    <PatientLayout sidebar={sidebar}>
      <ExerciseLevel />

      <div className="grid xl:grid-cols-2 gap-6 mt-10 xl:mt-20">
        <div className="max-xl:max-h-100 flex-center">
          <img className="max-w-full max-h-full" src="/assets/images/patient/scanner-poster.png" alt="" />
        </div>
        <div className="flex flex-col justify-center max-xl:text-center xl:pr-15">
          <h2>
            {t('patient.aiScanTitle')}.
          </h2>
          <p className="mt-6">
            {t('patient.aiScanDescription')}
          </p>
          <p className="typo-xs text-center mt-6">
            {t('patient.aiScanHelperText')}
          </p>
          {lastScan && !lastScan.isPhotosValid() && (
            <Button className="mt-6" theme="primary" fullWidth disabled={loading} onClick={onCreatePhoto}>
              {!lastScan?.front?.src ? t('patient.createPhoto') : t('common.continue')}
            </Button>
          )}
        </div>
      </div>

      {!loading && showNewScanPopup && (
        <NewScanPopup
          onCreate={onTryNewScan}
          onCancel={onRedirectToScanResultPage}
        />
      )}

      {scanning && (
        <ScanningLoader
          className="w-screen h-screen fixed top-0 left-0 z-max p-13"
        />
      )}
    </PatientLayout>
  );
};
