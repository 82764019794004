import {HttpService} from "./http.service";
import {PaginateRequestDto} from "../resources/dtos";
import {PhotoTipModel} from "../resources/models";
import {formatPaginatedResult} from "../utils/helpers";

export class TipsService {

  static search(query: PaginateRequestDto = {}, hasSpinner = true) {
    return HttpService.get('/tips', query, hasSpinner)
       .then((result) => formatPaginatedResult<PhotoTipModel>(PhotoTipModel, result.data));
  }

  static find(id: string, hasSpinner = true): Promise<PhotoTipModel> {
    return HttpService.get(`/tips/${id}`, {}, hasSpinner)
      .then((result) => new PhotoTipModel(result.data));
  }
}
