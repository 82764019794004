import React, {useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import {PatientLayout} from "../../../../layouts";
import {
  ExerciseCard,
  ExerciseChallengeCard,
  ExerciseDailyProgram,
  ExerciseLevel,
  ExerciseTutorialCard,
  ScanNeedPopup,
  TrainingModal,
} from "../../../../parts";
import {useSelector} from "../../../../redux/store";
import {STATUS} from "../../../../resources/enums";
import {ExerciseModel} from "../../../../resources/models";

export const Exercises = () => {
  const navigate = useNavigate();

  const { scan, challenge, dailyExercises, trainStatus } = useSelector((root) => root.patient);
  const remainTrainCount = trainStatus?.remainTrainCount || 0;

  const [showNeedScanPopup, setShowNeedScanPopup] = useState(false);
  const [trainingExercise, setTrainingExercise] = useState<ExerciseModel>();

  const remainExercises = useMemo(() => {
    const remainIds = dailyExercises
      .map((item, index) => ({
        id: item.id,
        index,
        doneAt: item.scan?.doneAt ? new Date(item.scan.doneAt).getTime() : 0,
      }))
      .sort((a, b) => (a.doneAt - b.doneAt) || (b.index - a.index))
      .slice(0, remainTrainCount)
      .map((item) => item.id);
    return dailyExercises.filter((item) => remainIds.includes(item.id));
  }, [dailyExercises, remainTrainCount]);

  useEffect(() => {
    if (scan?.status !== STATUS.COMPLETED) {
      setShowNeedScanPopup(true);
    }
  }, [scan]);

  const onStartTraining = () => {
    if (remainExercises.length) {
      setTrainingExercise(remainExercises[0]);
    }
  };

  const onTrainingFinished = (result: 'finished' | 'skipped' | 'cancelled') => {
    if (result === 'finished' || result === 'skipped') {
      const id = remainExercises.findIndex((item) => item.id === trainingExercise.id);
      const nextId = (id + 1) % remainExercises.length;
      setTrainingExercise(id === nextId ? undefined : remainExercises[nextId]);
    } else {
      setTrainingExercise(undefined);
    }
  };

  const sidebar = (
    <>
      <ExerciseChallengeCard />
      <ExerciseTutorialCard className="mt-4" />
    </>
  );

  return (
    <PatientLayout sidebar={sidebar}>
      <ExerciseLevel />

      <ExerciseDailyProgram
        className="relative mt-10"
        canTrain={remainTrainCount > 0}
        onStartTraining={onStartTraining}
      />

      {(scan && challenge) && (
        <div className="grid xl:grid-cols-2 gap-4 mt-8">
          {dailyExercises.map((exercise) => (
            <ExerciseCard
              key={exercise.id}
              exercise={exercise}
              active={remainExercises.some((item) => item.id === exercise.id)}
              onClick={() => setTrainingExercise(exercise)}
            />
          ))}

          {/*<ExerciseHardQuestionCard />*/}
        </div>
      )}

      {showNeedScanPopup && (
        <ScanNeedPopup
          onContinue={() => navigate('/scanner')}
          onClose={() => setShowNeedScanPopup(false)}
        />
      )}

      {trainingExercise && (
        <TrainingModal
          key={trainingExercise.id}
          exercise={trainingExercise}
          onClose={onTrainingFinished}
        />
      )}
    </PatientLayout>
  );
};
