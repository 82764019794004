import React, {FC} from "react";
import {useDispatch} from "react-redux";
import {Trans, useTranslation} from "react-i18next";
import classNames from "classnames";
import {Button, Popup} from "../../components";
import {CONFIG} from "../../constants";
import {patchAccount, setToken} from "../../redux/reducers/auth.reducer";
import {ToastService, UserConsentsService} from "../../services";

export interface IDisclaimerPopupProps {
  className?: string;
}

export const DisclaimerPopup: FC<IDisclaimerPopupProps> = ({
  className,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onAgree = () => {
    UserConsentsService.create().then(() => {
      dispatch(patchAccount({
        isFirstLogin: false,
      }));
    }).catch((err) => {
      ToastService.showHttpError(err, t('toast.requestFailed'));
    });
  };

  const onCancel = () => {
    dispatch(setToken(null));
  };

  return (
    <Popup
      contentClass={classNames('text-typo1', className)}
      width="32rem"
      disableBackdrop
      onClose={onCancel}
    >
      <h1>{t("disclaimer.title")}</h1>
      <p className="mt-1">
        <Trans i18nKey="disclaimer.body" values={{ appName: CONFIG.APP_NAME }} />
      </p>
      <Button className="mt-4" fullWidth onClick={onAgree}>
        {t("disclaimer.agree")}
      </Button>
      <Button className="mt-4" variant="outlined" fullWidth onClick={onCancel}>
        {t("disclaimer.cancel")}
      </Button>
    </Popup>
  );
};
