import React, {FC} from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import classNames from "classnames";
import {CommentQuestionIcon} from "../../icons";

export interface IDoctorSupportCardProps {
  className?: string;
}

export const DoctorSupportCard: FC<IDoctorSupportCardProps> = ({
  className,
}) => {
  const { t } = useTranslation();

  return (
    <Link
      className={classNames('card3 block px-4 py-3', className)}
      to="/doctors"
    >
      <div className="flex items-center">
        <CommentQuestionIcon size={24} />
        <h6 className="text-inherit ml-3 mr-4">{t('common.doctorSupport')}</h6>
        <i className="fa fa-angle-right p-1 ml-auto" />
      </div>
      <p className="mt-2">
        {t('patient.doctorSupportCardText')}.
      </p>
    </Link>
  );
};
